import React from "react";

const ChatHeader = () => {
  return (
    <div className="chat-header">
      <i className="uil uil-comment"></i>
      <h1>المحادثات</h1>
    </div>
  );
};

export default ChatHeader;
