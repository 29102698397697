import "./features.css";
import { Feature } from "../home/home";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function AllFeatures() {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("userInfo") &&
      !localStorage.getItem("adminName")
    ) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <section className="features">
        <div className="container">
          <div className="features-boxes features-lists">
            <Feature
              image={"/assets/video.svg"}
              header="الفيديوهات"
              path="/video-list"
            >
              ستجد في هذه الصفحة مجموعة الفيديوهات المسجلة الخاصة بالشرح طوال
              العام الدراسي لكل السنين الدراسية
            </Feature>

            <Feature
              image={"/assets/sheet.svg"}
              header="الشيتات"
              path="/chooseSheet"
            >
              ستجد في هذه الصفحة الشيتات الخاصة بالطلاب طوال العام الدراسي مع
              الاجابات لكل السنين الدراسية
            </Feature>
            <Feature
              image={"/assets/exam.svg"}
              header="الإمتحانات الورقية"
              path="/chooseExam"
            >
              ستجد في هذه الصفحة الامتحانات الخاصة بالطلاب طوال العام الدراسي مع
              الاجابات لكل السنين الدراسية
            </Feature>
            <Feature
              image={"/assets/quiz.svg"}
              header="الاختبارات الإلكترونية"
              path="/online-exams"
            >
              ستجد في هذه الصفحة العديد من الاختبارات الإلكترونية علي منهجك
              التعليمي لكي تطور من مستواك التعليمي
            </Feature>
            <Feature
              image={"/assets/board.svg"}
              header="السبورات"
              path="/blackboardsList"
            >
              {" "}
              ستجد في هذه الصفحة السبورات الخاصة بالشرح طوال العام الدراسي
              لتساعدك أثناء الدراسة والمراجعة
            </Feature>
            <Feature
              image={"/assets/audio.svg"}
              header="التسجيلات"
              path="/audio-list"
            >
              {" "}
              ستجد في هذه الصفحة مجموعة الصوتيات المسجلة الخاصة بالشرح طوال
              العام الدراسي لكل السنين الدراسية
            </Feature>
            {/* <Feature
              image={"https://aminelgazy.mathjewel.com/exam.svg"}
              header="مذكرات"
              path="/books"
            >
              {" "}
              ستجد في هذه الصفحه النماذج اليومية للشيتات الخاصة بالحصص اليومية
              لكل مجموعة بالاضافة الي الاجابات الخاصه
            </Feature> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default AllFeatures;
