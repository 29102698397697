import "../home/home.css";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { ar } from "date-fns/locale";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("userInfo") &&
      !localStorage.getItem("adminName")
    ) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <Testimonials />
    </>
  );
};

export function MainTitle({ children }) {
  return <h1 className="main-title">{children}</h1>;
}

export function Button({ className = "", path = "/", text }) {
  return (
    <Link to={path} className={className}>
      {text}
    </Link>
  );
}

function Testimonial({ image, children, name, date }) {
  return (
    <div
      className="testimonial-box"
      data-aos="fade-down"
      data-aos-duration="1300"
    >
      <img src={image} alt="طالب" />
      <div className="reviewer-data">
        <span>{name}</span>
        <span>{date}</span>
      </div>

      <p>{children}</p>
    </div>
  );
}

function Testimonials() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get("/reviews");
        const filteredResponse = response?.data.filter(
          (review) => review.approve === true
        );
        setReviews(filteredResponse);
        console.log(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReviews();
  }, []);

  return (
    <section className="testimonials reviewsSection">
      <div className="container">
        {reviews?.length === 0 && (
          <div className="no-exam-message">
            <i className="uil uil-exclamation-triangle"></i>
            <h1>لا يوجد تقييمات حتي الان</h1>
            <Button text="اضافة تقييم" path={`/add-review`} />
          </div>
        )}
        <div className="testimonials-boxes">
          {reviews
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((review) => (
              <Testimonial
                image="/assets/avatar-1.png"
                key={review?._id}
                name={review?.name}
                date={
                  review
                    ? format(new Date(review?.createdAt), "yyyy/M/d", {
                        locale: ar,
                      })
                    : ""
                }
              >
                {review?.message}
              </Testimonial>
            ))}
        </div>
      </div>
    </section>
  );
}

export default Home;
