import React from "react";
import "./chat.css";

function Avatar({ vistorId, username, online }) {
  const colors = [
    "bg-teal-200",
    "bg-red-200",
    "bg-purple-200",
    "bg-green-200",
    "bg-blue-200",
    "bg-yellow-200",
  ];
  const vistorIdBase10 = parseInt(vistorId, 10);
  const colorIndex = vistorIdBase10 % colors.length;
  const color = colors[colorIndex];

  // console.log(color);

  return (
    <div className={`user-avatar ${color}`}>
      <span>{username?.[0]}</span>
      {online && <span className="online-mark"></span>}
      {!online && <span className="online-mark off"></span>}
    </div>
  );
}

export default Avatar;
