import React, { useEffect, useState } from "react";
import "./payment.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Choose = () => {
  const [grade, setGrade] = useState("");
  const [type, setType] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const gradeData = {
    grade,
    type,
  };

  let handleRedirect = () => {
    if (!grade) {
      return toast.error("برجاء اختيار الصف..!");
    }
    if (!type) {
      return toast.error("برجاء اختيار النوع..!");
    }

    navigate("/booking", { state: gradeData });
  };

  useEffect(() => {
    async function fectchVistor() {
      if (localStorage.getItem("id")) {
        const response = await axios.get(
          `/vistors/${localStorage.getItem("id")}`
        );
        setData(response?.data);
      }
    }
    fectchVistor();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const check = () => {
    localStorage.removeItem("id");
    window.location.reload();
  };

  return (
    <div className="choose">
      <div className="container">
        {!localStorage.getItem("id") && (
          <>
            <select value={grade} onChange={(e) => setGrade(e.target.value)}>
              <option value="اختر الصف" hidden>
                اختر الصف
              </option>
              <option value="1prep">الأول الإعدادي</option>
              <option value="2prep">الثاني الإعدادي</option>
              <option value="3prep">الثالث الإعدادي</option>
              <option value="1sec">الأول الثانوي</option>
              <option value="2sec">الثاني الثانوي</option>
              <option value="3sec">الثالث الثانوي</option>
            </select>
            <select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="اختر النوع" hidden>
                اختر النوع
              </option>
              <option value="centre">سنتر</option>
              <option value="online">أونلاين</option>
            </select>
            <button onClick={handleRedirect}>تأكيد</button>
          </>
        )}
        {localStorage.getItem("id") && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {data?.check === false ? (
              <>
                <img
                  src="https://i.postimg.cc/XqnzLbwx/wait.gif"
                  alt=""
                  style={{ width: "200px" }}
                />
                <h2>طلب الحجز قيد المراجعة</h2>
              </>
            ) : (
              <>
                <img
                  src="https://i.postimg.cc/W35GRLB5/verified.gif"
                  alt=""
                  style={{ width: "200px" }}
                />
                <h2>تم تأكيد طلب الحجز بنجاح</h2>
                <button style={{ marginTop: "10px" }} onClick={check}>
                  طلب حجز اخر
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Choose;
