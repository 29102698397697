import { Link, useNavigate } from "react-router-dom";
import "../audioList/lessonList.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "../home/home";
import { clientUrl } from "../../config";
import { useSelector } from "react-redux";

function VideoList() {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("userInfo") &&
      !localStorage.getItem("adminName")
    ) {
      navigate("/login");
    }
  }, [navigate]);

  const [videos, setVideos] = useState([]);
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get("/videos");
        console.log(response.data);
        setVideos(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchVideos();
  }, []);
  return (
    <>
      <LessonListSection />
    </>
  );
}

function PlayerIcons({ id }) {
  const [shared, setShared] = useState(false);

  const handleShareClick = async () => {
    const videoUrl = `${clientUrl}/video-list/${id}`;
    try {
      await navigator.clipboard.writeText(videoUrl);
      setShared(true);
      setTimeout(() => {
        setShared(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="icons">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25px"
        height="25px"
        viewBox="0 0 24 24"
        fill="none"
        onClick={handleShareClick}
        style={{ cursor: "pointer" }}
      >
        {shared ? (
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15l-5-5 1.41-1.41L11 14.17l7.59-7.59L20 8l-9 9z"
            fill="#1C274C"
          />
        ) : (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z"
            fill="#1C274C"
          />
        )}
      </svg>
      <Link to={`/video-list/${id}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
            fill="#1C274C"
          />
        </svg>
      </Link>
    </div>
  );
}

function LessonListSection() {
  return (
    <section className="lessonList-section">
      <div className="container">
        <LessonItem />
      </div>
    </section>
  );
}

export function LessonItem() {
  const [videos, setVideos] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get("/videos");
        setVideos(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchVideos();
  }, []);

  const filteredVideos = userInfo?.grad
    ? videos?.filter((video) => video?.grad === userInfo?.grad)
    : videos;
  return (
    <>
      <div
        className="lesson-list"
        style={
          filteredVideos.length === 0
            ? { width: "380px", marginTop: "150px" }
            : { width: "100%" }
        }
      >
        {filteredVideos.length === 0 && (
          <div className="no-exam-message" style={{ marginTop: 0 }}>
            <i className="uil uil-exclamation-triangle"></i>
            <h1>لا يوجد فيديوهات</h1>
            <Button text="الصفحة الرئيسية" path={`/`} />
          </div>
        )}
        {filteredVideos.length > 0 && <h1>قائمه الدروس</h1>}
        <ul>
          {filteredVideos?.map((video) => (
            <li key={video._id}>
              <span>{video.title}</span>
              <PlayerIcons id={video._id} videoUrl={video.video} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
export default VideoList;
