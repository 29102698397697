import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "../home/home";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import "./showExam.css";
import { useSelector } from "react-redux";

Modal.setAppElement("#root");

function ShowExam() {
  const navigate = useNavigate();
  const [quizzes, setQuizzes] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [avatar] = useState("https://aminelgazy.mathjewel.com/avatar-1.png");
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      !localStorage.getItem("userInfo") &&
      !localStorage.getItem("adminName")
    ) {
      navigate("/login");
    } else {
      fetchQuizzes();
    }
  }, [navigate]);

  const fetchQuizzes = async () => {
    try {
      const response = await axios.get("/quizzes");
      setQuizzes(response?.data.quizzes);
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    }
  };

  const filteredQuizzes = userInfo?.grad
    ? quizzes?.filter(
        (quiz) => quiz?.grad === userInfo?.grad && quiz?.status === "Enabled"
      )
    : quizzes?.filter((quiz) => quiz?.status === "Enabled");

  const handleStartQuiz = (quiz) => {
    setSelectedQuiz(quiz);
    setShowPopup(true);
  };

  const handleConfirm = () => {
    localStorage.setItem("studentName", name);
    setShowPopup(false);
    navigate(`/online-exams/${selectedQuiz._id}`);
  };

  return (
    <>
      <StudentLibrary>
        <div className="myExam-List">
          {filteredQuizzes?.map((quiz) => (
            <div className="exam" key={quiz?._id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h1>{quiz?.title}</h1>
                <h3>{quiz?.duration} دقيقة</h3>
              </div>
              <Button
                text="عرض"
                onClick={() => handleStartQuiz(quiz)}
                className="general-btn"
                path={`/online-exams/${quiz?._id}`}
              />
            </div>
          ))}
        </div>
      </StudentLibrary>

      <Modal
        isOpen={showPopup}
        onRequestClose={() => setShowPopup(false)}
        className="popup-content"
        overlayClassName="popup-overlay"
      >
        <h2>ابدأ الاختبار</h2>
        <img src={avatar} alt="Avatar" className="avatar" />
        <input
          type="text"
          placeholder="أدخل اسمك"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="name-input"
        />
        <button onClick={handleConfirm} className="confirm-btn">
          تأكيد
        </button>
      </Modal>
    </>
  );
}

export function StudentLibrary({ children }) {
  return (
    <section className="student-library">
      <div className="container">{children}</div>
    </section>
  );
}

export default ShowExam;
