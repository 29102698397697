import "./booking.css";
import {useLocation, useNavigate } from "react-router-dom";


export default function Booking() {
  return (
    <>
      <BookingDetails />
    </>
  );
}
function CorrectIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      style={{ fill: "#0075ff" }}
    >
      <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M21.707,12.707l-7.56,7.56 c-0.188,0.188-0.442,0.293-0.707,0.293s-0.52-0.105-0.707-0.293l-3.453-3.453c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0 l2.746,2.746l6.853-6.853c0.391-0.391,1.023-0.391,1.414,0S22.098,12.316,21.707,12.707z"></path>
    </svg>
  );
}
function BookingDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const gradeData = location.state;

  console.log(gradeData);

  const data = {
    "1prep": {
      centre: {
        month: "60",
        book: "120",
      },
      online: {
        month: "100 جنيه شهريا",
      },
    },
    "2prep": {
      centre: {
        month: "60",
        book: "120",
      },
      online: {
        month: "100 جنيه شهريا",
      },
    },
    "3prep": {
      centre: {
        month: "65",
        book: "130",
      },
      online: {
        month: "100 جنيه شهريا",
      },
    },
    "1sec": {
      centre: {
        month: "75",
        book: "150",
      },
      online: {
        month: "120 جنيه شهريا",
      },
    },
    "2sec": {
      centre: {
        month: "75",
        book: "150",
      },
      online: {
        month: "120 جنيه شهريا",
      },
    },
    "3sec": {
      centre: {
        month: "130",
        book: "450",
      },
      online: {
        month: "200 جنيه شهريا",
      },
    },
  };

  const finalData = data[gradeData?.grade][gradeData?.type];

  console.log(finalData);

  let handleRedirect = () => {
    navigate("/check-register", { state: gradeData });
  };

  return (
    <section className="booking-section">
      <div className="container">
        <div className="booking-details">
          <div className="booking-price">
            <span
              style={{
                marginTop: "0",
              }}
            >
              {finalData.month} {finalData.book && "جنيه نصف شهر 8"}
            </span>
            {finalData.book && (
              <span
                style={{
                  marginTop: "0",
                }}
              >
                {finalData.book} المذكرات والورق
              </span>
            )}
          </div>
          <span
            style={{ fontSize: "20px", marginBottom: "15px", display: "block" }}
          >
            رقم التحويل
          </span>
          <div className="booking-number">
            <span>01012457129</span>
          </div>

          <p
            style={{
              marginTop: "10px",
            }}
          >
            تعليمات
          </p>
          <ul>
            <li>
              <CorrectIcon /> تأكد من كتابة الرقم بشكل صحيح{" "}
            </li>
            <li>
              {" "}
              <CorrectIcon /> تاكد من تحويل المبلغ المطلوب بشكل صحيح
            </li>
            <li>
              {" "}
              <CorrectIcon /> احتفظ بصورة التحويل وقم بارسالها في الصفحة التالية
            </li>
            <li></li>
          </ul>
          <button
            className="general-btn"
            onClick={handleRedirect}
            style={{
              color: "var(--white-color)",
              backgroundColor: "var(--main-color)",
            }}
          >
            الحجز
          </button>
        </div>
      </div>
    </section>
  );
}
