import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useEffect, useContext } from "react";
import { serverUrl } from "./config";
import Courses from "./pages/Courses/courses";
import Booking from "./pages/Booking/booking";
import Register from "./pages/register/register";
import Login from "./pages/login/login";
import Navbar from "./pages/navbar/navbar";
import Exam from "./pages/Exams/exam";
import ShowExam from "./pages/showExams/showExam";
import Sheets from "./pages/sheets/sheet";
import MyExams from "./pages/myExams/myExams";
import AllFeatures from "./pages/Features/features";
import SchoolBoards from "./pages/Blackboards/blackboards";
import SchoolExams from "./pages/schoolExam/schoolExam";
import SchoolSheets from "./pages/schoolSheet/schoolSheet";
import Account from "./pages/account/account";
import BlackboardsList from "./pages/blackboardsList/blackBoardsList";
import LessonVideo from "./pages/LessonVideo/lessonVideo";
import AddExam from "./pages/Admin/AddExam";
import ShowAllExams from "./pages/Admin/ShowAllExams";
import AddSheet from "./pages/Admin/AddSheet";
import ShowAllSheets from "./pages/Admin/ShowAllSheets";
import AddBoard from "./pages/Admin/AddBoard";
import ShowAllBoards from "./pages/Admin/ShowAllBoards";
import AddVideo from "./pages/Admin/AddVideo";
import VideoList from "./pages/videoList/videoList";
import ShowAllVideos from "./pages/Admin/ShowAllVideos";
import AddAudio from "./pages/Admin/AddAudio";
import AudioList from "./pages/audioList/audioList";
import LessonAudio from "./pages/audioList/lessonAudio";
import ShowAllAudios from "./pages/Admin/ShowAllAudios";
import AddBook from "./pages/Admin/AddBook";
import Books from "./pages/Books/books";
import ShowAllBooks from "./pages/Admin/ShowAllBooks";
import ShowAllData from "./pages/Admin/ShowAllData";
import AddStudent from "./pages/Admin/AddStudent";
import ShowAllStudents from "./pages/Admin/ShowAllStudents";
import Reviews from "./pages/reviews/Reviews";
import AddReview from "./pages/reviews/add-review";
import SchoolReviews from "./pages/reviews/school-reviews";
import Chat from "./pages/chat/Chat";
import { UserContextProvider, UserContext } from "./pages/UserContext";
import AllDegress from "./pages/Admin/Degrees/AllDegress";
import AddOnePrep from "./pages/Admin/Degrees/AddOnePrep";
import AllStudents from "./pages/Admin/Students/AllStudents";
import AddOnePrepStudents from "./pages/Admin/Students/AddOnePrepStudents";
import ParentLogin from "./pages/parentLogin/ParentLogin";
import Parent from "./pages/parentLogin/Parent";
import RegisterChat from "./pages/chat/RegisterChat";
import ShowAllChecks from "./pages/Admin/ShowAllChecks";
import ShowAllDoneChecks from "./pages/Admin/ShowAllDoneChecks";
import Choose from "./payment/Choose";
import AdminLogin from "./pages/Admin/AdminLogin";
import Footer from "./pages/footer/Footer";
import ScrollToTop from "./pages/scrollToTop/ScrollToTop";
import AddQuiz from "./pages/Admin/AddQuiz";
import Result from "./pages/Exams/Result";
import ShowAllQuizzess from "./pages/Admin/ShowAllQuizzess";
import AddMedia from "./pages/Admin/AddMedia";
import Media from "./pages/media/Media";
import ShowAllMedia from "./pages/Admin/ShowAllMedia";
import ForgetPassword from "./pages/passwords/forgetPassword";
import ResetPassword from "./pages/passwords/resetPassword";
import AddTwoPrepStudents from "./pages/Admin/Students/AddTwoPrepStudents";
import AddThreePrepStudents from "./pages/Admin/Students/AddThreePrepStudents";
import AddOneSecStudents from "./pages/Admin/Students/AddOneSecStudents";
import AddTwoSecStudents from "./pages/Admin/Students/AddTwoSecStudents";
import AddThreeSecStudents from "./pages/Admin/Students/AddThreeSecStudents";
import AddTwoPrep from "./pages/Admin/Degrees/AddTwoPrep";
import AddThreePrep from "./pages/Admin/Degrees/AddThreePrep";
import AddOneSec from "./pages/Admin/Degrees/AddOneSec";
import AddTwoSec from "./pages/Admin/Degrees/AddTwoSec";
import AddThreeSec from "./pages/Admin/Degrees/AddThreeSec";

axios.defaults.baseURL = `${serverUrl}/api`;
axios.defaults.withCredentials = true;

function App() {
  const { setUsername, setId, setToken } = useContext(UserContext);

  useEffect(() => {
    AOS.init();

    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios
        .get("/vistors")
        .then((response) => {
          setUsername(response.data.username);
          setId(response.data.vistorId);
          setToken(token);
        })
        .catch((error) => {
          console.error("Error fetching user data", error);
        });
    }
  }, [setUsername, setId, setToken]);

  return (
    <UserContextProvider>
      <BrowserRouter>
        <ToastContainer />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/features" element={<AllFeatures />} />
          <Route path="/account" element={<Account />} />

          <Route path="/dashboard/add-quiz" element={<AddQuiz />} />
          <Route path="/online-exams" element={<ShowExam />} />
          <Route path="/online-exams/:id" element={<Exam />} />
          <Route path="/results/:id" element={<Result />} />
          <Route path="/dashboard/quizzes" element={<ShowAllQuizzess />} />

          <Route path="/add-review" element={<AddReview />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/school-reviews" element={<SchoolReviews />} />

          <Route path="/dashboard/add-exam" element={<AddExam />} />
          <Route path="/chooseExam" element={<MyExams />} />
          <Route path="/schoolExams/:id" element={<SchoolExams />} />
          <Route path="/school-exams" element={<ShowAllExams />} />

          <Route path="/dashboard/add-sheet" element={<AddSheet />} />
          <Route path="/chooseSheet" element={<Sheets />} />
          <Route path="/schoolSheets/:id" element={<SchoolSheets />} />
          <Route path="/school-sheets" element={<ShowAllSheets />} />

          <Route path="/dashboard/add-board" element={<AddBoard />} />
          <Route path="/blackboardsList" element={<BlackboardsList />} />
          <Route path="/blackboardsList/:id" element={<SchoolBoards />} />
          <Route path="/school-boards" element={<ShowAllBoards />} />

          <Route path="/dashboard/add-video" element={<AddVideo />} />
          <Route path="/video-list" element={<VideoList />} />
          <Route path="/video-list/:id" element={<LessonVideo />} />
          <Route path="/school-videos" element={<ShowAllVideos />} />

          <Route path="/dashboard/add-audio" element={<AddAudio />} />
          <Route path="/audio-list" element={<AudioList />} />
          <Route path="/audio-list/:id" element={<LessonAudio />} />
          <Route path="/school-audios" element={<ShowAllAudios />} />

          <Route path="/dashboard/add-book" element={<AddBook />} />
          <Route path="/books" element={<Books />} />
          <Route path="/school-books" element={<ShowAllBooks />} />

          <Route path="/dashboard/add-student" element={<AddStudent />} />
          <Route path="/school-students" element={<ShowAllStudents />} />

          <Route path="/dashboard/add-media" element={<AddMedia />} />
          <Route path="/dashboard/media" element={<ShowAllMedia />} />
          <Route path="/media" element={<Media />} />

          <Route path="/dashboard/all-data" element={<ShowAllData />} />

          <Route path="/chat-register" element={<RegisterChat />} />
          <Route path="/chat" element={<Chat />} />

          <Route path="/dashboard/all-degrees" element={<AllDegress />} />
          <Route path="/dashboard/add-1prep-degrees" element={<AddOnePrep />} />
          <Route path="/dashboard/add-2prep-degrees" element={<AddTwoPrep />} />
          <Route
            path="/dashboard/add-3prep-degrees"
            element={<AddThreePrep />}
          />
          <Route path="/dashboard/add-1sec-degrees" element={<AddOneSec />} />
          <Route path="/dashboard/add-2sec-degrees" element={<AddTwoSec />} />
          <Route path="/dashboard/add-3sec-degrees" element={<AddThreeSec />} />

          <Route path="/dashboard/all-students" element={<AllStudents />} />
          <Route
            path="/dashboard/add-1prep-students"
            element={<AddOnePrepStudents />}
          />
          <Route
            path="/dashboard/add-2prep-students"
            element={<AddTwoPrepStudents />}
          />
          <Route
            path="/dashboard/add-3prep-students"
            element={<AddThreePrepStudents />}
          />
          <Route
            path="/dashboard/add-1sec-students"
            element={<AddOneSecStudents />}
          />
          <Route
            path="/dashboard/add-2sec-students"
            element={<AddTwoSecStudents />}
          />
          <Route
            path="/dashboard/add-3sec-students"
            element={<AddThreeSecStudents />}
          />

          <Route path="/parent-login" element={<ParentLogin />} />
          <Route path="/parent" element={<Parent />} />

          <Route path="/admin-login" element={<AdminLogin />} />

          <Route path="/check-choose" element={<Choose />} />
          <Route path="/check-register" element={<RegisterChat />} />
          <Route path="/check-students" element={<ShowAllChecks />} />
          <Route path="/done-check-students" element={<ShowAllDoneChecks />} />
        </Routes>
      </BrowserRouter>
      <ScrollToTop />
    </UserContextProvider>
  );
}

export default App;
