import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import "./exam.css";

function Exam() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (
      !localStorage.getItem("userInfo") &&
      !localStorage.getItem("adminName")
    ) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await axios.get(`/quizzes/${id}`);
        setQuiz(response.data.quiz);
        const durationInSeconds = response.data.quiz.duration * 60;

        const storedTime = localStorage.getItem(`quizTime_${id}`);
        if (storedTime) {
          setTimeLeft(Number(storedTime));
        } else {
          setTimeLeft(durationInSeconds);
        }

        const storedAnswers = JSON.parse(
          localStorage.getItem(`quizAnswers_${id}`)
        );
        if (storedAnswers) {
          setSelectedAnswers(storedAnswers);
        }
      } catch (error) {
        console.error("Error fetching quiz:", error);
      }
    };

    fetchQuiz();
  }, [id]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleSubmit();
    }

    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem(`quizTime_${id}`, newTime);
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft, id]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        localStorage.setItem(`quizTime_${id}`, timeLeft);
      } else {
        const storedTime = localStorage.getItem(`quizTime_${id}`);
        if (storedTime) {
          setTimeLeft(Number(storedTime));
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [timeLeft, id]);

  const handleAnswerClick = (questionIndex, choiceIndex) => {
    const updatedAnswers = {
      ...selectedAnswers,
      [questionIndex]: choiceIndex,
    };
    setSelectedAnswers(updatedAnswers);

    localStorage.setItem(`quizAnswers_${id}`, JSON.stringify(updatedAnswers));
  };

  const { userInfo } = useSelector((state) => state.auth);

  const handleSubmit = async () => {
    try {
      const answers = Object.values(selectedAnswers);

      const name = studentName || userInfo?.name;
      const email = studentEmail || userInfo?.email;
      const grad = userInfo?.grad;
      const code = userInfo?.code;

      const response = await axios.post(`/quizzes/${id}/submit`, {
        answers,
        name,
        email,
        grad,
        code,
      });

      localStorage.removeItem(`quizAnswers_${id}`);
      localStorage.removeItem(`quizTime_${id}`);

      navigate(`/results/${response?.data.resultId}`);
    } catch (error) {
      console.error("Error submitting quiz:", error);
    }
  };

  if (!quiz) return <p>Loading...</p>;

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <>
      <div className="timer">
        <p className="time-text">الوقت المتبقي: {formatTime(timeLeft)}</p>
      </div>
      <ExamSection
        questions={quiz.questions}
        selectedAnswers={selectedAnswers}
        onAnswerClick={handleAnswerClick}
      />
      <div className="form-section">
        <div className="container">
          <input
            type="text"
            placeholder="أدخل اسمك"
            value={studentName || userInfo?.name}
            onChange={(e) => setStudentName(e.target.value)}
            className="form-input"
            readOnly
            disabled
          />
          <input
            type="email"
            placeholder="أدخل بريدك الإلكتروني"
            value={studentEmail || userInfo?.email}
            onChange={(e) => setStudentEmail(e.target.value)}
            className="form-input"
            readOnly
            disabled
          />
          <button onClick={handleSubmit} className="submit-btn">
            إرسال
          </button>
        </div>
      </div>
    </>
  );
}

function ExamSection({ questions, selectedAnswers, onAnswerClick }) {
  return (
    <section className="exam-section">
      <div className="container">
        {questions.map((question, index) => (
          <ExamDetails
            key={index}
            num={index + 1}
            question={question}
            selectedAnswer={selectedAnswers[index]}
            onAnswerClick={(choiceIndex) => onAnswerClick(index, choiceIndex)}
          />
        ))}
      </div>
    </section>
  );
}

function ExamDetails({ num, question, selectedAnswer, onAnswerClick }) {
  return (
    <div className="exam-details">
      <p className="exam-question">{question.questionText}</p>
      <div className="exam-answers">
        <ExamAnswers
          choices={question.choices}
          selectedAnswer={selectedAnswer}
          onAnswerClick={onAnswerClick}
        />
      </div>
    </div>
  );
}

function ExamAnswers({ choices, selectedAnswer, onAnswerClick }) {
  return (
    <ul>
      {choices.map((choice, index) => (
        <li
          key={index}
          className={selectedAnswer === index ? "selected" : ""}
          onClick={() => onAnswerClick(index)}
        >
          {index + 1}. {choice}
        </li>
      ))}
    </ul>
  );
}

export default Exam;
