import { StudentLibrary } from "../showExams/showExam";
import { Button } from "../home/home";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./myExams.css";
import { useSelector } from "react-redux";

function Exams() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      !localStorage.getItem("userInfo") &&
      !localStorage.getItem("adminName")
    ) {
      navigate("/login");
    }
  }, [navigate]);

  const [exams, setExams] = useState([]);
  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await axios.get("/exams");
        console.log(response.data);
        setExams(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchExams();
  }, []);

  const filteredExams = userInfo?.grad
    ? exams?.filter((exam) => exam?.grad === userInfo?.grad)
    : exams;

  return (
    <>
      <StudentLibrary>
        <div className="mySheet-List">
          {filteredExams?.length === 0 && (
            <div className="no-exam-message">
              <i className="uil uil-exclamation-triangle"></i>
              <h1>لا يوجد امتحانات حتي الان</h1>
              <Button text="الصفحة الرئيسية" path={`/`} />
            </div>
          )}
          {filteredExams?.map((exam) => (
            <div className="sheet">
              <h1>{exam.title}</h1>
              <Button
                text="عرض"
                path={`/schoolExams/${exam._id}`}
                className="general-btn"
              />
            </div>
          ))}
        </div>
      </StudentLibrary>
    </>
  );
}

export default Exams;
