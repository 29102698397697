import { Link, useNavigate } from "react-router-dom";
import "./dashboard.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { ar } from "date-fns/locale";
import { useDownloadExcel } from "react-export-table-to-excel";

const ShowAllDoneChecks = () => {
  const [vistors, setVistors] = useState([]);
  const [filteredVistors, setFilteredVistors] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const tableRef = useRef(null);

  const navigate = useNavigate();
  if (!localStorage.getItem("adminName")) {
    navigate("/admin-login");
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "بيانات الحجز",
    sheet: "StudentsData",
  });

  useEffect(() => {
    const fetchVistors = async () => {
      try {
        const response = await axios.get("/vistors");
        const filteredResponse = response?.data.filter(
          (vistor) => vistor.check === true
        ); // Filter for check === true
        setVistors(filteredResponse);
        setFilteredVistors(filteredResponse);
        console.log(filteredResponse);
      } catch (error) {
        console.log(error);
      }
    };
    fetchVistors();
  }, []);

  useEffect(() => {
    setFilteredVistors(
      vistors.filter((vistor) => {
        return (
          (selectedGrade === "" || vistor.gradeAr === selectedGrade) &&
          (selectedType === "" || vistor.typeAr === selectedType)
        );
      })
    );
  }, [selectedGrade, selectedType, vistors]);

  const handleDelete = async (e, vistorId) => {
    e.preventDefault();
    try {
      await axios.delete(`/vistors/${vistorId}`);
      setVistors(vistors.filter((vistor) => vistor._id !== vistorId));
      toast.success("تم الحذف بنجاح");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log(error.request);
        toast.error("حدث خطأ اثناء العملية. يرجى المحاولة مرة اخرى.");
      } else {
        console.log("Error", error.message);
        toast.error("حدث خطأ اثناء العملية. يرجى المحاولة مرة اخرى.");
      }
    }
  };

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleCheck = async (vistorId) => {
    try {
      await axios.patch(`/vistors/${vistorId}`, {
        check: true,
      });
      toast.success("تم تحديث الحالة بنجاح");
    } catch (error) {
      console.error(error);
      toast.error("حدث خطأ أثناء تحديث الحالة، يرجى المحاولة مرة أخرى.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "yyyy/M/d - h:mm:ss a", { locale: ar });
  };

  return (
    <div className="show-all-exams">
      <div className="container">
        <div className="table_component" role="region" tabIndex="0">
          <div className="table-head">
            <h1>طلبات الحجز</h1>
            <button onClick={onDownload}>تحميل البيانات</button>
            <form style={{ width: "470px" }}>
              <span>فلترة</span>
              <select value={selectedGrade} onChange={handleGradeChange}>
                <option value="" hidden>
                  اختر الصف
                </option>
                <option value="الأول الإعدادي">الأول الإعدادي</option>
                <option value="الثاني الإعدادي">الثاني الإعدادي</option>
                <option value="الثالث الإعدادي">الثالث الإعدادي</option>
                <option value="الأول الثانوي">الأول الثانوي</option>
                <option value="الثاني الثانوي">الثاني الثانوي</option>
                <option value="الثالث الثانوي">الثالث الثانوي</option>
              </select>
              <select value={selectedType} onChange={handleTypeChange}>
                <option value="" hidden>
                  اختر النوع
                </option>
                <option value="سنتر">سنتر</option>
                <option value="أونلاين">أونلاين</option>
              </select>
              <button type="button">
                <i className="uil uil-sliders-v-alt"></i>
              </button>
            </form>
          </div>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>رقم الطالب</th>
                <th>اسم الطالب</th>
                <th>رقم الهاتف</th>
                <th>رقم ولي الأمر</th>
                <th>الصف</th>
                <th>المجموعة</th>
                <th>النوع</th>
                <th>حالة الحجز</th>
              </tr>
            </thead>
            <tbody>
              {filteredVistors.map((vistor, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{vistor?.username}</td>
                  <td>{vistor?.phone}</td>
                  <td>{vistor?.parentPhone}</td>
                  <td>{vistor?.gradeAr}</td>
                  <td>{vistor?.time || "لا يوجد"}</td>
                  <td>{vistor?.typeAr}</td>
                  <td className="action-icons">
                    <div>
                      <i
                        className="uil uil-check show-exam"
                        style={{
                          backgroundColor: vistor.check ? "#25c600" : "#ccc",
                        }}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowAllDoneChecks;
