import React from "react";
import Avatar from "./Avatar";
import "./chat.css";

const Contact = ({
  vistorId,
  username,
  onClick,
  selected,
  online,
  unreadCount,
}) => {
  return (
    <div
      key={vistorId}
      onClick={() => onClick(vistorId)}
      className={`chat-user ${selected ? "active" : ""}`}
    >
      <Avatar online={online} username={username} vistorId={vistorId} />
      <span>{username}</span>
      {unreadCount > 0 && <span className="unread-count">{unreadCount}</span>}
    </div>
  );
};

export default Contact;
