import axios from "axios";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { ar } from "date-fns/locale";
import "./media.css";
import { useNavigate } from "react-router-dom";

const Media = () => {
  const [media, setMedia] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("userInfo") &&
      !localStorage.getItem("adminName")
    ) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get("/media");
        setMedia(response?.data);
        console.log(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMedia();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "yyyy/M/d - h:mm:ss a", { locale: ar });
  };

  return (
    <div className="work">
      <div className="container">
        <div className="work-boxes">
          {media?.map((media, index) => (
            <MediaBox
              key={index}
              image={media?.image}
              title={media?.title}
              date={media?.createdAt ? formatDate(media?.createdAt) : ""}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

function MediaBox({ image, title, duration, date }) {
  return (
    <div className="work-box" data-aos="fade-down" data-aos-duration={duration}>
      <div className="work-img">
        <a href={image} target="_blank" rel="noreferrer">
          <img src={image} alt="media" />
        </a>
      </div>
      <h1 style={{ fontSize: "22px" }}>{title}</h1>
      <hr />
      <h2 style={{ fontSize: "18px" }}>تاريخ النشر: {date}</h2>
    </div>
  );
}

export default Media;
