import React, { useEffect, useState } from "react";
import axios from "axios";
import "./home.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../footer/Footer";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { ar } from "date-fns/locale";

const Home = () => {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    AOS.init();
    const fetchMedia = async () => {
      try {
        const response = await axios.get("/media");
        setMedia(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMedia();
  }, []);

  const { userInfo } = useSelector((state) => state.auth);

  return (
    <>
      <Header />
      <Features />
      <Testimonials />
      <Stages />
      <Footer />
      <div className="ads-container">
        {media.length > 0 && (
          <AdItem
            key={media[media.length - 1]._id}
            image={media[media.length - 1].image}
            title={media[media.length - 1].title}
          />
        )}
      </div>
    </>
  );
};

function AdItem({ image, title }) {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="ad-item">
      <button className="close-button" onClick={() => setIsVisible(false)}>
        X
      </button>
      <img src={image} alt={title} />
      <div className="ad-info">
        <Link to={"/media"}>
          <p>{title}</p>
        </Link>
      </div>
    </div>
  );
}

function Header() {
  useEffect(() => {
    let animatedH1 = document.querySelector(".animated-h1");
    let width = animatedH1.offsetWidth + 25;
    let i = 30;

    let a = setInterval(() => {
      animatedH1.style.width = `${i}px`;
      if (i > width) {
        let b = setInterval(() => {
          i -= 30;
          if (i === 0) {
            clearInterval(b);
          }
        }, 200);
      } else {
        i += 30;
      }
    }, 200);
  }, []);

  return (
    <section className="header">
      <div className="container">
        <div className="teacher-heading" data-aos="fade-left">
          <h1 className="animated-h1">
            الأمين في <span>اللغة العربية</span>
          </h1>
          <p>
            منصة الأمين هي منصة متخصصة في مادة اللغة العربية تحتوي علي نماذج
            الشيتات ونماذج الامتحانات وتسجيلات الحصص والفيديوهات الخاصة بالشرح
            ومتابعة الطالب
          </p>
          <div className="buttons">
            <Button path="/login" text="تسجيل الدخول" />
            <Button path="/register" text="إنشاء حساب" />
            <Button path="/forget-password" text="نسيت كلمة المرور" />
          </div>
        </div>
        <div className="teacher-background" data-aos="fade-right">
          <img
            alt="امين الغازي"
            src="https://i.postimg.cc/0NhypQwf/amin-2.jpg"
          />
        </div>
      </div>
    </section>
  );
}

export function MainTitle({ children }) {
  return <h1 className="main-title">{children}</h1>;
}

export function Feature({ image, header, children, path }) {
  return (
    <div className="feature-box" data-aos="fade-down" data-aos-duration="1300">
      <div className="feature-background">
        <img src={image} alt={header} />
        <span>{header}</span>
      </div>
      <p>{children}</p>
      <Button path={path} text="عرض" />
    </div>
  );
}

function Features() {
  return (
    <section className="features">
      <MainTitle>الخدمات</MainTitle>
      <div className="container">
        <div className="features-boxes">
          <Feature
            image={"/assets/video.svg"}
            header="الفيديوهات"
            path="/video-list"
          >
            ستجد في هذه الصفحة مجموعة الفيديوهات المسجلة الخاصة بالشرح طوال
            العام الدراسي لكل السنين الدراسية
          </Feature>
          <Feature
            image={"/assets/sheet.svg"}
            header="الشيتات"
            path="/chooseSheet"
          >
            ستجد في هذه الصفحة الشيتات الخاصة بالطلاب طوال العام الدراسي مع
            الاجابات لكل السنين الدراسية
          </Feature>
          <Feature
            image={"/assets/exam.svg"}
            header="الإمتحانات الورقية"
            path="/chooseExam"
          >
            ستجد في هذه الصفحة الامتحانات الخاصة بالطلاب طوال العام الدراسي مع
            الاجابات لكل السنين الدراسية
          </Feature>
        </div>
        <Button path="/features" className="show-all-btn" text="عرض المزيد" />
      </div>
    </section>
  );
}

export function Button({ className = "", path = "/", text }) {
  return (
    <Link to={path} className={className}>
      {text}
    </Link>
  );
}

function Testimonial({ image, children, name, date }) {
  return (
    <div
      className="testimonial-box"
      data-aos="fade-down"
      data-aos-duration="1300"
    >
      <img src={image} alt="طالب" />
      <div className="reviewer-data">
        <span>{name}</span>
        <span>{date}</span>
      </div>

      <p>{children}</p>
    </div>
  );
}

function Testimonials() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get("/reviews");
        const filteredResponse = response?.data.filter(
          (review) => review.approve === true
        );
        setReviews(filteredResponse);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReviews();
  }, []);

  return (
    <section className="testimonials">
      <MainTitle>الآراء</MainTitle>
      <div className="container">
        <div className="testimonials-boxes">
          {reviews
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 6)
            .map((review) => (
              <Testimonial
                image="/assets/avatar-1.png"
                key={review?._id}
                name={review?.name}
                date={
                  review
                    ? format(new Date(review?.createdAt), "yyyy/M/d", {
                        locale: ar,
                      })
                    : ""
                }
              >
                {review?.message}
              </Testimonial>
            ))}
        </div>
        <Button path="/reviews" className="show-all-btn" text="عرض المزيد" />
      </div>
    </section>
  );
}

function Stages() {
  return (
    <section className="stages">
      <MainTitle>الخطوات المنهجية</MainTitle>
      <div className="container">
        <div className="right-side">
          <div className="stage-list">
            <div className="stage-item">
              <h1>الخطوة الأولى</h1>
              <p>
                شرح المنهج بالكامل وجميع الملاحظات والأخطاء التي يقع فيها الطالب
              </p>
            </div>
            <div className="stage-item">
              <h1>الخطوة الثالثة</h1>
              <p>تمرين الطالب على الشرح عن طريق إعطائه شيت صغير</p>
            </div>
          </div>
        </div>
        <div className="middle-side">
          <div className="stage">
            <div className="circle-1">
              <div className="nested-circle"></div>
            </div>
            <div className="circle-2">
              <div className="nested-circle"></div>
            </div>
            <div className="circle-3">
              <div className="nested-circle"></div>
            </div>
          </div>
        </div>
        <div className="left-side">
          <div className="stage-item">
            <h1>الخطوة الثانية</h1>
            <p>
              حل أسئلة وتمارين خاصة بالجزء المشروح وأيضا حل أسئلة الواجب
              والمتابعة المستمرة مع الطالب
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
