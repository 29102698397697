import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RegisterAndLogin } from "../register/register";

export default function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginSubmitHandler = async (e) => {
    e.preventDefault();
    if (!email) {
      return toast.error("برجاء كتابة الايميل..!");
    }
    if (!password) {
      return toast.error("برجاء كتابة كلمة المرور..!");
    }

    try {
      const response = await axios.post("/admin", {
        email,
        password,
      });

      console.log(response?.data);
      if (response.data) {
        localStorage.setItem("adminName", response?.data.admin.name);
        toast.success("تم تسجيل الدخول بنجاح!");
        navigate("/dashboard/all-data");
      }
    } catch (error) {
      toast.error("البيانات خاطئة..!");
    }
  };

  return (
    <RegisterAndLogin className="login-section" header="تسجيل دخول">
      <div className="container">
        <div className="login-info">
          <div className="photo">
            <img
              src="/assets/avatar-1.png"
              alt="طالب"
            />
          </div>
          <form onSubmit={loginSubmitHandler}>
            <div className="form-group">
              <label>الايميل</label>
              <input
                type="email"
                placeholder="الايميل"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>كلمة المرور</label>
              <input
                type="password"
                placeholder="كلمة المرور"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="general-btn create-btn">
              تسجيل الدخول{" "}
            </button>
          </form>
        </div>
      </div>
    </RegisterAndLogin>
  );
}
