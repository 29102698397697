import { LessonItem } from "../audioList/lessonAudio";
import "./lessonVideo.css";
import "../../../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { ar } from "date-fns/locale";
import { clientUrl } from "../../config";
import { useDispatch, useSelector } from "react-redux";

function LessonVideo() {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("userInfo") &&
      !localStorage.getItem("adminName")
    ) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <LessonVideoDetails />
    </>
  );
}

function LessonVideoDetails() {
  return (
    <section className="lesson-video">
      <div className="container" style={{ flexDirection: "column" }}>
        <div className="video-top" style={{ display: "flex" }}>
          <VideoPlayer />
          <VideoItem />
        </div>
        <CommentsSection />
      </div>
    </section>
  );
}

function PlayerIcons({ id }) {
  const [shared, setShared] = useState(false);

  const handleShareClick = async () => {
    const videoUrl = `${clientUrl}/video-list/${id}`;
    try {
      await navigator.clipboard.writeText(videoUrl);
      setShared(true);
      setTimeout(() => {
        setShared(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="icons">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25px"
        height="25px"
        viewBox="0 0 24 24"
        fill="none"
        onClick={handleShareClick}
        style={{ cursor: "pointer" }}
      >
        {shared ? (
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15l-5-5 1.41-1.41L11 14.17l7.59-7.59L20 8l-9 9z"
            fill="#1C274C"
          />
        ) : (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z"
            fill="#1C274C"
          />
        )}
      </svg>
      <Link to={`/video-list/${id}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
            fill="#1C274C"
          />
        </svg>
      </Link>
    </div>
  );
}

function VideoItem() {
  const { userInfo } = useSelector((state) => state.auth);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get("/videos");
        setVideos(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchVideos();
  }, []);

  // فلترة الفيديوهات بناءً على grad
  const filteredVideos = userInfo?.grad
    ? videos?.filter((video) => video?.grad === userInfo?.grad)
    : videos;

  return (
    <div
      className="lesson-list"
      style={{ height: "740px", overflowY: "scroll" }}
    >
      <h1>قائمه الدروس</h1>
      <ul>
        {filteredVideos?.map((video) => (
          <li key={video._id}>
            <span>{video.title}</span>
            <PlayerIcons id={video._id} />
          </li>
        ))}
      </ul>
    </div>
  );
}

function VideoPlayer() {
  const [videoInfo, setVideoInfo] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchSingleVideo = async () => {
      try {
        const response = await axios.get(`/videos/${id}`);
        setVideoInfo(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingleVideo();
  }, [id]);

  const formattedDate = videoInfo
    ? format(new Date(videoInfo.createdAt), "yyyy/M/d", { locale: ar })
    : "";

  useEffect(() => {
    const preventActions = (event) => {
      if (
        event.key === "PrintScreen" ||
        (event.ctrlKey && (event.key === "s" || event.key === "p"))
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", preventActions);
    document.addEventListener("keydown", preventActions);

    return () => {
      document.removeEventListener("contextmenu", preventActions);
      document.removeEventListener("keydown", preventActions);
    };
  }, []);

  return (
    <div className="sidebar-video">
      <div className="video-player">
        <iframe
          style={{
            width: "800px",
            height: "500px",
            borderRadius: "16px",
            maxWidth: "100%",
          }}
          src={`https://www.youtube.com/embed/${videoInfo?.videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media;"
          allowFullScreen
        ></iframe>
      </div>

      <div className="lesson-video-details">
        <h1>{videoInfo?.title}</h1>
        <ul>
          <li>
            <span>تصنيف الدرس</span>
            <span>{videoInfo?.type}</span>
          </li>
          <li>
            <span>تاريخ النشر</span>
            <span>{formattedDate}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

function CommentsSection() {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [replyText, setReplyText] = useState("");
  const [replyingTo, setReplyingTo] = useState(null);
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`/comments/videos/${id}/comments`);
        setComments(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchComments();
  }, [id]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/comments/videos/${id}/comments`, {
        text: newComment,
        user: userInfo?.name || localStorage.getItem("adminName"),
      });
      setComments([...comments, response.data]);
      setNewComment("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/comments/${commentId}/reply`, {
        text: replyText,
        user: userInfo?.name || localStorage.getItem("adminName"),
      });
      setComments(
        comments.map((comment) =>
          comment._id === commentId
            ? { ...comment, replies: [...comment.replies, response.data] }
            : comment
        )
      );
      setReplyText("");
      setReplyingTo(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike = async (commentId, replyId) => {
    try {
      const response = await axios.patch(`/comments/${commentId}/like`, {
        replyId: replyId || null,
      });
      setComments(
        comments.map((comment) =>
          comment._id === commentId
            ? {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply._id === replyId
                    ? { ...reply, likes: response.data.likes }
                    : reply
                ),
                likes:
                  comment._id === commentId
                    ? response.data.likes
                    : comment.likes,
              }
            : comment
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="comments-section">
      <h2>التعليقات</h2>
      <ul>
        {comments.map((comment) => (
          <li key={comment._id}>
            <div className="comment">
              <div className="comment-header">
                <span className="comment-user">
                  <i class="uil uil-user-circle"></i>
                  {comment.user}
                </span>
                <div className="comment-icons">
                  <button
                    onClick={() => handleLike(comment._id)}
                    className={`like-button ${
                      comment.likes > 0 ? "liked" : ""
                    }`}
                  >
                    {comment.likes} <i class="uil uil-thumbs-up"></i>
                  </button>

                  <button onClick={() => setReplyingTo(comment._id)}>
                    <i className="uil uil-comment"></i>
                  </button>
                </div>
              </div>
              <p className="comment-text">{comment.text}</p>
              <span className="comment-time">
                {format(new Date(comment.createdAt), "yyyy/M/d", {
                  locale: ar,
                })}
              </span>
              <div className="replies">
                {comment.replies && comment.replies.length > 0 && (
                  <div className="replies">
                    {comment.replies.map((reply) => (
                      <div className="reply" key={reply._id}>
                        <span className="comment-user">{reply.user}</span>
                        <p className="comment-text">{reply.text}</p>
                        <span className="comment-time">
                          {format(new Date(reply.createdAt), "yyyy/M/d", {
                            locale: ar,
                          })}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {replyingTo === comment._id && (
                <form
                  className="reply-form"
                  onSubmit={(e) => handleReplySubmit(e, comment._id)}
                >
                  <input
                    type="text"
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    placeholder="اكتب ردك هنا..."
                    required
                  />
                  <button type="submit">إرسال</button>
                </form>
              )}
            </div>
          </li>
        ))}
      </ul>
      <form className="comment-form" onSubmit={handleCommentSubmit}>
        <input
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="اكتب تعليقك هنا..."
          required
        />
        <button type="submit">إرسال</button>
      </form>
    </div>
  );
}

export default LessonVideo;
