import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { RegisterAndLogin } from "../register/register";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const { token } = useParams();

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  let passwordSubmitHandler = async (e) => {
    e.preventDefault();

    if (!password) {
      return toast.error("برجاء كتابة كلمة المرور..!");
    }

    try {
      const response = await axios.post(
        `/users/reset-password/${token}`,
        { password: password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response?.data);
      toast.success("تم تعيين كلمة المرور..!");
      navigate("/login");
    } catch (error) {
      console.log(error);
      toast.error("برجاء إعادة المحاولة مرة أخري..!");
    }
  };

  return (
    <RegisterAndLogin className="login-section" header="تسجيل دخول">
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="login-info">
          <div className="photo">
            <img
              style={{ width: "170px" }}
              src="https://i.postimg.cc/h41Z0KbQ/forgot-password.png"
              alt="طالب"
            />
          </div>
          <form onSubmit={passwordSubmitHandler}>
            <div className="form-group">
              <label>كلمة المرور الجديدة</label>
              <input
                type="password"
                placeholder="كلمة المرور الجديدة"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="general-btn create-btn">
              تحديث{" "}
            </button>
          </form>
        </div>
      </div>
    </RegisterAndLogin>
  );
}
