import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./dashboard.css";

function AddQuiz() {
  const [title, setTitle] = useState("");
  const [grad, setGrad] = useState("");
  const [duration, setDuration] = useState(""); // حقل جديد للمدة
  const [questions, setQuestions] = useState([
    {
      questionText: "",
      choices: ["", ""],
      correctAnswer: "",
    },
  ]);

  const maxChoices = 5;

  const addChoice = (questionIndex) => {
    const newQuestions = [...questions];
    if (newQuestions[questionIndex].choices.length < maxChoices) {
      newQuestions[questionIndex].choices.push("");
      setQuestions(newQuestions);
    }
  };

  const handleInputChange = (e, questionIndex, choiceIndex) => {
    const { name, value } = e.target;
    const newQuestions = [...questions];
    if (name === "questionText") {
      newQuestions[questionIndex].questionText = value;
    } else if (name === "choice") {
      newQuestions[questionIndex].choices[choiceIndex] = value;
    } else if (name === "correctAnswer") {
      newQuestions[questionIndex].correctAnswer = value;
    }
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { questionText: "", choices: ["", ""], correctAnswer: "" },
    ]);
  };

  const removeQuestion = (questionIndex) => {
    const newQuestions = questions.filter(
      (_, index) => index !== questionIndex
    );
    setQuestions(newQuestions);
  };

  const submitQuiz = () => {
    const quizData = {
      title,
      grad,
      duration, // إضافة المدة إلى بيانات الاختبار
      questions: questions.map((question) => ({
        ...question,
        correctAnswer: question.correctAnswer - 1,
      })),
    };

    axios
      .post("/quizzes", quizData)
      .then((response) => {
        console.log(response?.data);
        toast.success("تم إرسال الاختبار بنجاح!");
      })
      .catch((error) => {
        toast.error("حدث خطأ أثناء إرسال الاختبار!");
      });
  };

  return (
    <div className="quiz-container">
      <div className="container">
        <div className="quiz-header">
          اسم الاختبار:{" "}
          <input
            type="text"
            className="quiz-input"
            placeholder="أدخل اسم الاختبار"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <label>السنة الدراسية</label>
        <div className="input-group">
          <select value={grad} onChange={(e) => setGrad(e.target.value)}>
            <option value="choose" disabled selected hidden>
              اختر الصف
            </option>
            <option value="prep" disabled>
              المرحلة الاعدادية
            </option>
            <option value="الاول الاعدادي">الاول الاعدادي</option>
            <option value="الثاني الاعدادي">الثاني الاعدادي</option>
            <option value="الثالث الاعدادي">الثالث الاعدادي</option>
            <option value="sec" disabled>
              المرحلة الثانوية
            </option>
            <option value="الاول الثانوي">الاول الثانوي</option>
            <option value="الثاني الثانوي">الثاني الثانوي</option>
            <option value="الثالث الثانوي">الثالث الثانوي</option>
          </select>
        </div>

        {/* إضافة حقل جديد للمدة */}
        <div className="quiz-header">
          مدة الاختبار (بالدقائق):{" "}
          <input
            type="number"
            className="quiz-input"
            placeholder="أدخل مدة الاختبار بالدقائق"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </div>

        <div className="quiz-section-header" style={{ marginTop: "20px" }}>
          أسئلة الاختبار:
        </div>
        {questions.map((question, qIndex) => (
          <div className="quiz-question" key={qIndex}>
            <label>السؤال {qIndex + 1}</label>
            <input
              type="text"
              className="quiz-input"
              placeholder="اكتب السؤال هنا..."
              name="questionText"
              value={question.questionText}
              onChange={(e) => handleInputChange(e, qIndex)}
            />
            <div>الخيارات:</div>
            {question.choices.map((choice, cIndex) => (
              <div className="quiz-choice" key={cIndex}>
                <label>{cIndex + 1}:</label>
                <input
                  type="text"
                  className="quiz-choice-input"
                  placeholder={`أضف الخيار ${cIndex + 1}`}
                  name="choice"
                  value={choice}
                  onChange={(e) => handleInputChange(e, qIndex, cIndex)}
                />
              </div>
            ))}
            {question.choices.length < maxChoices && (
              <button
                className="add-choice-button"
                onClick={() => addChoice(qIndex)}
              >
                أضف خيار جديد
              </button>
            )}
            <input
              type="text"
              className="correct-answer-input"
              placeholder="أدخل الإجابة الصحيحة..."
              name="correctAnswer"
              value={question.correctAnswer}
              onChange={(e) => handleInputChange(e, qIndex)}
            />
            <button
              className="quiz-button"
              onClick={() => removeQuestion(qIndex)}
            >
              حذف السؤال
            </button>
          </div>
        ))}
        <button className="quiz-button" onClick={addQuestion}>
          أضف سؤال جديد
        </button>
        <button className="quiz-button" onClick={submitQuiz}>
          إرسال الاختبار
        </button>
        <ToastContainer />
      </div>
    </div>
  );
}

export default AddQuiz;
