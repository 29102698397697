/* eslint-disable no-unused-vars */
import { Link, useNavigate } from "react-router-dom";
import "../dashboard.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";
import { format } from "date-fns";
import { ar } from "date-fns/locale";

const AddThreePrep = () => {
  const [excelRows, setExcelRows] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [finalScore, setFinalScore] = useState("");
  const [minScoreToRetake, setMinScoreToRetake] = useState("");
  const [examDate, setExamDate] = useState("");
  const [lessonNumber, setLessonNumber] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const navigate = useNavigate();
  if (!localStorage.getItem("adminName")) {
    navigate("/admin-login");
  }

  useEffect(() => {
    fetchDegrees();
  }, []);

  const fetchDegrees = async () => {
    try {
      const response = await axios.get("/degrees3prep");

      const allDegrees = response?.data || [];
      const filteredDegrees = allDegrees.filter(
        (degree) => degree.grad === "الصف الثالث الاعدادي"
      );
      setRows(filteredDegrees);
    } catch (error) {
      console.error("Error fetching degrees:", error);
      toast.error("حدث خطأ أثناء جلب البيانات!");
    }
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        setExcelRows(json);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const calculateDataSize = (data) => {
    const jsonString = JSON.stringify(data);
    const bytes = new Blob([jsonString]).size;
    return bytes / 1024;
  };

  const uploadData = async () => {
    setIsUploading(true);
    console.log(excelRows);

    try {
      const degreesResponse = await axios.get("/degrees3prep");
      const degreesList = degreesResponse?.data || [];

      const existingDegreesMap = degreesList.reduce((map, degree) => {
        map[degree.degreeId] = degree;
        return map;
      }, {});

      const degrees = excelRows.map((obj) => {
        const lessonIndex = parseInt(lessonNumber, 10) - 1;
        const existingDegree = existingDegreesMap[obj["الرقم"]];

        // إذا كان الطالب موجودًا بالفعل
        const updatedLessons = existingDegree
          ? existingDegree.lessons.map((lesson, index) => {
              const score = obj[`حصة ${index + 1}`];

              if (
                index < lessonIndex &&
                (lesson.score === "لم يتم تحديد درجة" ||
                  lesson.score === "غائب")
              ) {
                lesson.score = obj[`حصة ${index + 1}`] || "لم يتم تحديد درجة";
              }

              if (index === lessonIndex && score) {
                return {
                  ...lesson,
                  score:
                    score === "غ"
                      ? "غائب"
                      : score === "_"
                      ? "لم يتم تحديد درجة"
                      : score,
                  finalScore: finalScore || lesson.finalScore,
                  minScoreToRetake: minScoreToRetake || lesson.minScoreToRetake,
                  examDate: examDate || lesson.examDate,
                };
              }

              return lesson;
            })
          :
            [...Array(12).keys()].map((i) => {
              if (i < lessonIndex) {
                return {
                  score: obj[`حصة ${i + 1}`] || "لم يتم تحديد درجة",
                  finalScore: "0",
                  minScoreToRetake: "0",
                  examDate: null,
                };
              } else if (i === lessonIndex) {
                const score = obj[`حصة ${i + 1}`];
                return {
                  score:
                    score === "غ"
                      ? "غائب"
                      : score === "_"
                      ? "لم يتم تحديد درجة"
                      : score || "لم يتم تحديد درجة",
                  finalScore: finalScore || "0",
                  minScoreToRetake: minScoreToRetake || "0",
                  examDate: examDate || null,
                };
              } else {
                return {
                  score: "0",
                  finalScore: "0",
                  minScoreToRetake: "0",
                  examDate: null,
                };
              }
            });

        return {
          _id: existingDegree ? existingDegree._id : undefined,
          degreeId: obj["الرقم"],
          code: obj["الكود"] || "",
          name: obj["الاسم"] || "",
          time: obj["المجموعة"] || "",
          payment: obj["الدفع"] || "لم يتم الدفع",
          grad: "الصف الثالث الاعدادي",
          lessons: updatedLessons,
        };
      });

      console.log(degrees);

      const response = await axios.post("/degrees3prep", degrees, {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 3000000,
      });

      if (response?.data) {
        toast.success("تم رفع البيانات بنجاح!");
        fetchDegrees();
      }
    } catch (error) {
      console.error(
        `Error uploading data: ${error.message}`,
        error.response ? error.response.data : ""
      );
      toast.error("حدث خطأ أثناء رفع البيانات!");
    } finally {
      setIsUploading(false);
    }
  };

  const removeFile = async () => {
    const grad = "الصف الثالث الاعدادي";
    try {
      await axios.delete("/degrees3prep", { data: { grad } });
      setSelectedFile(null);
      setExcelRows([]);
      fetchDegrees();
      toast.success(`تم حذف بيانات ${grad} بنجاح..!`);
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.success(`تم حذف بيانات ${grad} بنجاح..!`);
    }
  };

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRows = rows.filter(
    (item) =>
      (selectedGroup ? item.time === selectedGroup : true) &&
      (searchTerm ? item.name.includes(searchTerm) : true)
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "yyyy/M/d - h:mm:ss a", { locale: ar });
  };

  const toggleRow = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const Lesson = ({ number, score, finalScore }) => (
    <div className="student-degree">
      <span className="lesson-name">حصة {number}</span>
      <span className="lesson-degree">
        {score === "غائب"
          ? "غائب"
          : score !== "لم يتم تحديد درجة" && finalScore !== 0
          ? `${score}/${finalScore}`
          : "لم يتم تحديد درجة"}
      </span>
    </div>
  );

  return (
    <div
      className="show-all-exams"
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <div className="container">
        <div className="upload-degrees">
          <form className="excel-form" onSubmit={(e) => e.preventDefault()}>
            <input type="file" onChange={readUploadFile} />
            <div>
              <label>رقم الحصة:</label>
              <input
                type="number"
                value={lessonNumber}
                onChange={(e) => setLessonNumber(e.target.value)}
              />
            </div>
            <div>
              <label>الدرجة النهائية:</label>
              <input
                type="number"
                value={finalScore}
                onChange={(e) => setFinalScore(e.target.value)}
              />
            </div>
            <div>
              <label>درجة المتابعة:</label>
              <input
                type="number"
                value={minScoreToRetake}
                onChange={(e) => setMinScoreToRetake(e.target.value)}
              />
            </div>
            <div>
              <label>تاريخ الحصة:</label>
              <input
                style={{ cursor: "pointer" }}
                type="date"
                value={examDate}
                onChange={(e) => setExamDate(e.target.value)}
              />
            </div>
            <button type="button" onClick={uploadData}>
              رفع
            </button>
            <button
              type="button"
              onClick={removeFile}
              style={{ backgroundColor: "var(--red-color)" }}
            >
              حذف
            </button>
          </form>
          {isUploading && (
            <div className="uploading-message">جاري الرفع...</div>
          )}
        </div>
      </div>
      <div className="container">
        <input
          type="text"
          placeholder="ابحث باسم الطالب"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            width: "100%",
            padding: "15px 10px",
            outline: "none",
            borderRadius: "12px",
            border: "1px solid var(--border-color)",
          }}
        />
      </div>

      <div className="container">
        <div className="table_component" role="region" tabIndex="0">
          <div className="table-head">
            <h1>الدرجات</h1>
            <div className="filter-search">
              <form style={{ width: "450px" }}>
                <span>فلترة</span>
                <select value={selectedGroup} onChange={handleGroupChange}>
                  <option value="" hidden>
                    اختر المجموعة
                  </option>
                  <option value="">الجميع</option>
                  <option value="السبت والثلاثاء">السبت والثلاثاء</option>
                  <option value="الاحد والاربعاء">الاحد والاربعاء</option>
                  <option value="الاثنين والخميس">الاثنين والخميس</option>
                </select>
                <button>
                  <i className="uil uil-sliders-v-alt"></i>
                </button>
              </form>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>الرقم</th>
                <th>كود الطالب</th>
                <th>اسم الطالب</th>
                <th>المجموعة</th>
                <th>الدفع</th>
                <th>اخر تحديث</th>
                <th>عرض الدرجات</th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.code}</td>
                    <td>{item?.name}</td>
                    <td>{item?.time}</td>
                    <td>{item?.payment}</td>
                    <td>{item?.updatedAt ? formatDate(item.updatedAt) : ""}</td>
                    <td className="action-icons">
                      <div onClick={() => toggleRow(index)}>
                        <i className="uil uil-eye show-degrees"></i>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={`student-tr ${
                      expandedRowIndex === index ? "expanded" : ""
                    }`}
                  >
                    <td colSpan={7} className="degrees-td">
                      <div className="student-degrees">
                        {[...Array(12).keys()].map((i) => (
                          <Lesson
                            key={i + 1}
                            number={i + 1}
                            score={
                              item.lessons[i]?.score || "لم يتم تحديد درجة"
                            }
                            finalScore={
                              item.lessons[i]?.finalScore || "لم يتم تحديد درجة"
                            }
                          />
                        ))}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddThreePrep;
