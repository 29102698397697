import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-about">
          <div className="companies">
            <div>
              <img src="/assets/logo.png" alt="الأمين" />
              <h2>الأمين في اللغة العربية</h2>
            </div>
            <div>
              <h2>Legacy Code</h2>
              <img src="/assets/legacy.png" alt="Made By:" />
            </div>
          </div>
          <hr />
          <div className="content">
            <div className="pages">
              <h2>الصفحات</h2>
              <ul>
                <div>
                  <li>
                    <Link href="/">الرئيسية</Link>
                  </li>
                  <li>
                    <Link href="/parent-login">خدمات ولي الأمر</Link>
                  </li>
                  <li>
                    <Link href="/add-review">قيمنا</Link>
                  </li>
                </div>
                <div>
                  <li>
                    <Link href="/features">خدمات الطالب</Link>
                  </li>
                  <li>
                    <Link href="/media">الاعلانات</Link>
                  </li>
                  <li>
                    <Link href="/reviews">الآراء</Link>
                  </li>
                </div>
              </ul>
            </div>
            {/* <div className="about">
              <h2>عن المنصة</h2>
              <p>
                منصة الأمين هي منصة متخصصة في مادة اللغة العربية تحتوي علي نماذج
                الشيتات ونماذج الامتحانات وتسجيلات الحصص والفيديوهات الخاصة
                بالشرح ومتابعة الطالب
              </p>
            </div> */}
            <div className="contact">
              <h2>تواصل معنا</h2>
              <div className="footer-social-media">
                <Link
                  to={"https://api.whatsapp.com/send/?phone=201008002446"}
                  target="_blank"
                >
                  <img src="/assets/social/whatsapp.svg" alt="واتساب" />
                </Link>
                <Link
                  to={"https://web.facebook.com/profile.php?id=100095295830204"}
                  target="_blank"
                >
                  <img src="/assets/social/facebook.svg" alt="فيسبوك" />
                </Link>
                <Link
                  to={"https://www.instagram.com/amen.elghazy/"}
                  target="_blank"
                >
                  <img src="/assets/social/instagram.svg" alt="انستجرام" />
                </Link>
                <Link to={"https://t.me/AmenElghazi"} target="_blank">
                  <img src="/assets/social/telegram.svg" alt="تيلجرام" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-copyright">
          <div className="footer-social-media">
            <Link to={"https://web.facebook.com/LegacyCodeTm"} target="_blank">
              <img src="/assets/social/facebook.svg" alt="فيسبوك" />
            </Link>
            <Link
              to={"https://api.whatsapp.com/send/?phone=201550102967"}
              target="_blank"
            >
              <img src="/assets/social/whatsapp.svg" alt="واتساب" />
            </Link>
            <Link
              to={"https://linkedin.com/company/legacycodetm/"}
              target="_blank"
            >
              <img src="/assets/social/linkedin.svg" alt="لينكد ان" />
            </Link>
            <Link
              to={"https://www.instagram.com/legacy__code404/"}
              target="_blank"
            >
              <img src="/assets/social/instagram.svg" alt="انستجرام" />
            </Link>
          </div>
          <p>
            All rights reserved to{" "}
            <a
              href={"https://www.facebook.com/xrepot"}
              target="_blank"
              rel="noreferrer"
            >
              Ahmed Sallam
            </a>{" "}
            and{" "}
            <a
              href={"https://www.facebook.com/profile.php?id=100023689124191"}
              target="_blank"
              rel="noreferrer"
            >
              Youssef Ashraf
            </a>
            , founders of{" "}
            <a
              href={"https://web.facebook.com/LegacyCodeTm"}
              target="_blank"
              rel="noreferrer"
            >
              Legacy Code
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
