import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { UserContext } from "../UserContext";
import Chat from "./Chat";
import { RegisterAndLogin } from "../register/register";
import { useLocation, useNavigate } from "react-router-dom";
import Booking from "../Booking/booking";
import "../Admin/dashboard.css";

const RegisterChat = () => {
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [payPhone, setPayPhone] = useState("");
  const [time, setTime] = useState("");
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // حالة لتحقق مما إذا كانت العملية جارية
  const navigate = useNavigate();
  const location = useLocation();

  const gradeData = location.state;
  console.log(gradeData);

  const timesData = {
    "1prep": {
      time1: "السبت والثلاثاء - 6 - الأمين",
      time2: "الأحد والأربعاء - 7:30 - الأمين",
      time3: "الإثنين والخميس - 2 - ابن سينا",
    },
    "2prep": {
      time1: "السبت والثلاثاء - 7:30 - الأمين",
      time2: "الأحد والأربعاء - 6 - الأمين",
      time3: "الإثنين والخميس - 3:30 - ابن سينا",
    },
    "3prep": {
      time1: "السبت والثلاثاء - 11:30 - الأمين",
      time2: "السبت والثلاثاء - 10 - الأمين",
      time3: "الأحد والأربعاء - 12:30 - الصفوة",
      time4: "الإثنين والخميس - 10 - ابن سينا",
    },
    "1sec": {
      time1: "السبت والثلاثاء - 1:30 - الأمين",
      time2: "الأحد والأربعاء - 4 - الصفوة",
      time3: "الأحد والأربعاء - 11:30 - كفر دميرة",
      time4: "الإثنين والخميس - 5:30 - ابن سينا",
      time5: "الإثنين والخميس - 7:30 - ميت الكرماء",
    },
    "2sec": {
      time1: "السبت والثلاثاء - 5 - الأمين",
      time2: "الأحد والأربعاء - 5 - الصفوة",
      time3: "الإثنين والخميس - 6:30 - ابن سينا",
      time4: "الإثنين والخميس - 8:30 - ميت الكرماء",
    },
    "3sec": {
      time1: "سنتر الامين - السبت والثلاثاء - 3 (مفارق بلقاس)",
      time2: "سنتر الصفوة - الاحد والاربعاء - 2 (تقسيم بهاء)",
      time3: "سنتر ابن سينا - الاثنين الخميس - 12 (تقسيم غنام)",
      time4: "سنتر Education - السبت والثلاثاء - 10 (ميت الكرماء)",
      time5: "سنتر النخبة - الاحد والاربعاء - 10 (كفر دميرة القديم)",
    },
  };

  const filledTimes = [
    "السبت والثلاثاء - 6 - الأمين",
    "السبت والثلاثاء - 7:30 - الأمين",
    "السبت والثلاثاء - 11:30 - الأمين",
    "الأحد والأربعاء - 7:30 - الأمين",
    "الأحد والأربعاء - 6 - الأمين",
    "الإثنين والخميس - 10 - ابن سينا",
    "السبت والثلاثاء - 1:30 - الأمين",
    "سنتر الامين - السبت والثلاثاء - 3 (مفارق بلقاس)",
  ]; // الأوقات المكتملة

  const finalData = timesData[gradeData.grade];
  const convertedFinalData = Object.values(finalData);

  const {
    setUsername: setLoggedInUsername,
    setId,
    setToken,
    username: loggedInUsername,
    id,
  } = useContext(UserContext);

  const registerSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true); // ضبط حالة التحميل إلى true عند بدء العملية

    if (!username) {
      setIsLoading(false);
      return toast.error("برجاء كتابة الاسم بالكامل..!");
    }
    if (!phone) {
      setIsLoading(false);
      return toast.error("برجاء كتابة رقم الهاتف..!");
    }
    if (!parentPhone) {
      setIsLoading(false);
      return toast.error("برجاء كتابة رقم ولي الامر..!");
    }
    if (!payPhone) {
      setIsLoading(false);
      return toast.error("برجاء كتابة رقم التحويل..!");
    }
    if (!time && gradeData.type === "centre") {
      setIsLoading(false);
      return toast.error("برجاء اختيار المعاد..!");
    }

    if (phone === parentPhone) {
      setIsLoading(false);
      return toast.error("ارقام الهواتف متطابقة..!");
    }
    if (!image) {
      setIsLoading(false);
      return toast.error("برجاء رفع صورة التحويل..!");
    }

    const vistorData = new FormData();
    vistorData.set("username", username);
    vistorData.set("phone", phone);
    vistorData.set("parentPhone", parentPhone);
    vistorData.set("payPhone", payPhone);
    vistorData.set("time", time);
    vistorData.set("type", gradeData.type);
    vistorData.set("grade", gradeData.grade);
    vistorData.append("image", image);

    try {
      const { data } = await axios.post("/vistors", vistorData, {
        withCredentials: true,
      });
      toast.success("تم ارسال طلب الحجز..!");
      setLoggedInUsername(username);
      setId(data.id);
      setToken(data.token);
      console.log(data);
      localStorage.setItem("id", data?._id);
    } catch (error) {
      console.error(error);
      toast.error("حدث خطأ أثناء التسجيل، يرجى المحاولة مرة أخرى.");
    } finally {
      setIsLoading(false); // ضبط حالة التحميل إلى false بعد انتهاء العملية
    }
  };

  return (
    <RegisterAndLogin className="login-section" header="تسجيل دخول">
      <div className="container">
        <div className="login-info">
          <form onSubmit={registerSubmitHandler}>
            <div className="form-group">
              <label>الاسم</label>
              <input
                type="text"
                placeholder="الاسم"
                name="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div
              className="form-groups"
              style={{ display: "flex", gap: "10px" }}
            >
              <div className="form-group" style={{ width: "50%" }}>
                <label>رقم الهاتف</label>
                <input
                  type="text"
                  placeholder="رقم الهاتف"
                  name="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div className="form-group" style={{ width: "50%" }}>
                <label>رقم ولي الامر</label>
                <input
                  type="text"
                  placeholder="رقم ولي الأمر"
                  name="tel"
                  value={parentPhone}
                  onChange={(e) => setParentPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>الرقم الذي تم التحويل منه</label>
              <input
                type="text"
                placeholder="الرقم الذي تم التحويل منه"
                name="tel"
                value={payPhone}
                onChange={(e) => setPayPhone(e.target.value)}
              />
            </div>
            {gradeData.type === "centre" && (
              <div className="input-group">
                <select
                  defaultValue="choose"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  style={{ margin: " 20px 0", marginTop: "0" }}
                >
                  <option value="choose" hidden>
                    اختر الميعاد
                  </option>
                  {convertedFinalData.map((item, index) => (
                    <option
                      value={item}
                      key={index}
                      disabled={filledTimes.includes(item)}
                    >
                      {item} {filledTimes.includes(item) ? "(اكتمل الحجز)" : ""}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <label style={{ marginBottom: "10px", display: "block" }}>
              صورة تحويل المبلغ علي الرقم الموجود في الصفحة السابقة
            </label>
            <div className="input-group" style={{ position: "relative" }}>
              <div className="upload-content" style={{ display: "none" }}>
                <i className="uil uil-image"></i>
              </div>
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <button
              type="submit"
              className="general-btn create-btn"
              style={{ marginTop: "25px" }}
              disabled={isLoading} // تعطيل الزر أثناء التحميل
            >
              {isLoading ? "جاري الحجز..." : "الحجز"}{" "}
              {/* نص الزر يتغير حسب حالة التحميل */}
            </button>
          </form>
        </div>
      </div>
    </RegisterAndLogin>
  );
};

export default RegisterChat;
