import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { RegisterAndLogin } from "../register/register";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ForgetPassword() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  let passwordSubmitHandler = async (e) => {
    e.preventDefault();

    if (!email) {
      return toast.error("برجاء كتابة الايميل..!");
    }

    try {
      const response = await axios.post(
        "/users/forget-password",
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response?.data);
      toast.success("تحقق من البريد الالكتروني..!");
      navigate("/login");
    } catch (error) {
      console.log(error);
      toast.error("برجاء إعادة المحاولة مرة أخري..!");
    }
  };

  return (
    <RegisterAndLogin className="login-section" header="تسجيل دخول">
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="login-info">
          <div className="photo">
            <img
              style={{ width: "170px" }}
              src="https://i.postimg.cc/h41Z0KbQ/forgot-password.png"
              alt="طالب"
            />
          </div>
          <form onSubmit={passwordSubmitHandler}>
            <div className="form-group">
              <label>الايميل</label>
              <input
                type="email"
                placeholder="الايميل"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button type="submit" className="general-btn create-btn">
              إرسال{" "}
            </button>
          </form>
        </div>
      </div>
    </RegisterAndLogin>
  );
}
