/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { ar } from "date-fns/locale";

const ShowAllQuizzess = () => {
  const [exams, setExams] = useState([]);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [students, setStudents] = useState([]);
  const navigate = useNavigate();

  // التأكد من أن المستخدم مسجل دخول كـ admin
  if (!localStorage.getItem("adminName")) {
    navigate("/admin-login");
  }

  useEffect(() => {
    fetchExams();
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get("/quizzes");
      setExams(response?.data.quizzes || []);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStudentsForExam = async (examId) => {
    try {
      const response = await axios.get(`/quizzes/${examId}/students`);
      setStudents(response?.data || []);
    } catch (error) {
      console.log(error);
      toast.error("حدث خطأ أثناء جلب بيانات الطلاب");
    }
  };

  const toggleRow = (index, examId) => {
    if (expandedRowIndex === index) {
      setExpandedRowIndex(null);
      setStudents([]);
    } else {
      setExpandedRowIndex(index);
      fetchStudentsForExam(examId);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? "لم يتم تحديد تاريخ"
      : format(date, "yyyy/M/d - h:mm:ss a", { locale: ar });
  };

  const handleQuizStatus = async (quizId, status) => {
    try {
      const response = await axios.put(`/quizzes/${quizId}`, {
        status: status,
      });

      setExams((prevExams) =>
        prevExams.map((exam) =>
          exam._id === quizId ? { ...exam, status } : exam
        )
      );

      toast.success("تم تحديث الحالة بنجاح");
    } catch (error) {
      console.error(error);
      toast.error("حدث خطأ أثناء تحديث الحالة، يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <div
      className="show-all-exams"
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <div className="container">
        <div className="table_component" role="region" tabIndex="0">
          <div className="table-head">
            <h1>الاختبارات الإلكترونية</h1>
          </div>
          <table>
            <thead>
              <tr>
                <th>الرقم</th>
                <th>اسم الاختبار</th>
                <th>تاريخ الإضافة</th>
                <th>عدد الأسئلة</th>
                <th>إجراءات</th>
              </tr>
            </thead>
            <tbody>
              {exams?.map((exam, index) => (
                <React.Fragment key={exam?._id}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{exam?.title}</td>
                    <td>{formatDate(exam?.createdAt)}</td>
                    <td>{exam?.questions?.length || 0}</td>
                    <td className="action-icons">
                      <div>
                        <div onClick={() => toggleRow(index, exam?._id)}>
                          <i className="uil uil-eye show-degrees"></i>
                        </div>
                        <div>
                          {exam?.status === "Enabled" ? (
                            <i
                              className="uil uil-toggle-on"
                              style={{
                                backgroundColor: "#33d391",
                                transition: "0.3s",
                              }}
                              onClick={() =>
                                handleQuizStatus(exam?._id, "Disabled")
                              }
                            ></i>
                          ) : (
                            <i
                              className="uil uil-toggle-off"
                              style={{
                                backgroundColor: "#ccc",
                                transition: "0.3s",
                              }}
                              onClick={() =>
                                handleQuizStatus(exam?._id, "Enabled")
                              }
                            ></i>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={`student-tr ${
                      expandedRowIndex === index ? "expanded" : ""
                    }`}
                  >
                    <td colSpan={5} className="students-td">
                      {expandedRowIndex === index && (
                        <div className="students-list">
                          <table>
                            <thead>
                              <tr>
                                <th>الرقم</th>
                                <th>اسم الطالب</th>
                                <th>الدرجة / عدد الأسئلة</th>
                              </tr>
                            </thead>
                            <tbody>
                              {students?.map((student, index) => (
                                <tr key={student._id}>
                                  <td>{index + 1}</td>
                                  <td>{student?.studentName}</td>
                                  <td>{`${student?.score} / ${exam?.questions.length}`}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowAllQuizzess;
