import { useNavigate } from "react-router-dom";
import "../dashboard.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";

const AddThreeSecStudents = () => {
  const [excelRows, setExcelRows] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [toastId, setToastId] = useState(null);
  const [showAddStudentPopup, setShowAddStudentPopup] = useState(false);
  const [newStudent, setNewStudent] = useState({
    code: "",
    name: "",
    time: "",
    phone: "",
    parentPhone: "",
    grad: "الصف الثالث الثانوي",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("adminName")) {
      navigate("/admin-login");
    } else {
      fetchStudents();
    }
  }, [navigate]);

  const fetchStudents = async () => {
    try {
      const response = await axios.get("/students");
      const filteredStudents = (response?.data || []).filter(
        (student) => student.grad === "الصف الثالث الثانوي"
      );
      setRows(filteredStudents);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        setExcelRows(json);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadData = async () => {
    if (!excelRows.length) {
      toast.error("لم يتم تحديد ملف للرفع.");
      return;
    }
    const id = toast.info("جاري رفع البيانات", { autoClose: false });
    setToastId(id);
    try {
      const studentsResponse = await axios.get("/students");
      const studentsList = studentsResponse?.data || [];

      const existingStudentsMap = studentsList.reduce((map, student) => {
        map[student.code] = student;
        return map;
      }, {});

      const students = excelRows.map((obj) => ({
        _id: existingStudentsMap[obj["الكود"]]
          ? existingStudentsMap[obj["الكود"]]._id
          : null,
        studentId: obj["الرقم"],
        code: obj["الكود"] || "",
        name: obj["الاسم"] || "",
        time: obj["المجموعة"] || "",
        phone: obj["رقم الهاتف"] || "",
        parentPhone: obj["رقم ولي الامر"] || "",
        adress: obj["العنوان"] || "",
        grad: "الصف الثالث الثانوي",
      }));

      const newStudents = students.filter(
        (x) => !x._id && x.grad === "الصف الثالث الثانوي"
      );

      if (newStudents.length) {
        await axios.post("/students", newStudents);
      }

      const updateStudents = students.filter(
        (x) => x._id && x.grad === "الصف الثالث الثانوي"
      );

      if (updateStudents.length) {
        await axios.post("/students/update-students", updateStudents);
      }

      toast.update(id, {
        render: "تم رفع البيانات بنجاح!",
        type: "success",
        autoClose: 3000,
        isLoading: false,
      });
      fetchStudents();
    } catch (error) {
      console.error(`Error: ${error}`);
      toast.update(id, {
        render: "حدث خطأ أثناء رفع البيانات!",
        type: "error",
        autoClose: 3000,
        isLoading: false,
      });
    }
  };

  const removeFile = async () => {
    try {
      await axios.post("/students/delete-group", {
        grad: "الصف الثالث الثانوي",
      });
      setSelectedFile(null);
      setExcelRows([]);
      fetchStudents();
      toast.success("تم حذف البيانات بنجاح!");
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("حدث خطأ أثناء حذف البيانات!");
    }
  };

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const filteredRows = rows.filter(
    (item) =>
      item.grad === "الصف الثالث الثانوي" &&
      (selectedGroup ? item.time === selectedGroup : true)
  );

  const handleAddStudentChange = (e) => {
    setNewStudent({ ...newStudent, [e.target.name]: e.target.value });
  };

  const handleAddStudentSubmit = async () => {
    try {
      await axios.post("/students", [newStudent]);
      setShowAddStudentPopup(false);
      toast.success("تم إضافة الطالب بنجاح!");
      fetchStudents();
    } catch (error) {
      console.error("Error adding student:", error);
      toast.error("حدث خطأ أثناء إضافة الطالب!");
    }
  };

  return (
    <div
      className="show-all-exams"
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <div className="container">
        <div className="upload-degrees">
          <form className="excel-form" onSubmit={readUploadFile}>
            <input type="file" onChange={readUploadFile} />
            <button type="button" onClick={uploadData}>
              رفع
            </button>
            <button
              type="button"
              onClick={removeFile}
              style={{ backgroundColor: "var(--red-color)" }}
            >
              حذف
            </button>
          </form>
          <button
            type="button"
            onClick={() => setShowAddStudentPopup(true)}
            style={{
              backgroundColor: "var(--main-color)",
              color: "var(--white-color)",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            إضافة طالب
          </button>
        </div>
      </div>

      <div className="container">
        <div className="table_component" role="region" tabIndex="0">
          <div className="table-head">
            <h1>الطلاب</h1>
            <div className="filter-search">
              <form style={{ width: "450px" }}>
                <span>فلترة</span>
                <select value={selectedGroup} onChange={handleGroupChange}>
                  <option value="" hidden>
                    اختر المجموعة
                  </option>
                  <option value="">الجميع</option>
                  <option value="السبت والثلاثاء">السبت والثلاثاء</option>
                  <option value="الاحد والاربعاء">الاحد والاربعاء</option>
                  <option value="الاثنين والخميس">الاثنين والخميس</option>
                </select>
                <button type="button">
                  <i className="uil uil-sliders-v-alt"></i>
                </button>
              </form>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>الرقم</th>
                <th>كود الطالب</th>
                <th>اسم الطالب</th>
                <th>المجموعة</th>
                <th>رقم الهاتف</th>
                <th>رقم ولي الأمر</th>
                <th>العنوان</th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.code}</td>
                    <td>{item?.name}</td>
                    <td>{item?.time}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.parentPhone}</td>
                    <td>{item?.adress}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showAddStudentPopup && (
        <div className="popup-overlay">
          <div
            className="popup-content"
            style={{
              backgroundColor: "var(--white-color)",
              padding: "20px",
              borderRadius: "10px",
              width: "500px",
              border: "none",
            }}
          >
            <h3>إضافة طالب جديد</h3>
            <form>
              <input
                type="text"
                name="code"
                placeholder="الكود"
                value={newStudent.code}
                onChange={handleAddStudentChange}
              />
              <input
                type="text"
                name="name"
                placeholder="الاسم"
                value={newStudent.name}
                onChange={handleAddStudentChange}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="grad"
                placeholder="الصف"
                value={newStudent.grad}
                onChange={handleAddStudentChange}
              />
              <input
                type="text"
                name="time"
                placeholder="المجموعة"
                value={newStudent.time}
                onChange={handleAddStudentChange}
              />
              <input
                type="text"
                name="phone"
                placeholder="رقم الهاتف"
                value={newStudent.phone}
                onChange={handleAddStudentChange}
              />
              <input
                type="text"
                name="parentPhone"
                placeholder="رقم ولي الأمر"
                value={newStudent.parentPhone}
                onChange={handleAddStudentChange}
              />
              <button type="button" onClick={handleAddStudentSubmit}>
                إضافة
              </button>
              <button
                type="button"
                onClick={() => setShowAddStudentPopup(false)}
                style={{
                  backgroundColor: "var(--red-color)",
                  marginTop: "10px",
                }}
              >
                إلغاء
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddThreeSecStudents;
