import { Link, useNavigate } from "react-router-dom";
import "./dashboard.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { formatDistanceToNowStrict } from "date-fns";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { ar } from "date-fns/locale";

const ShowAllChecks = () => {
  const [vistors, setVistors] = useState([]);
  const [filteredVistors, setFilteredVistors] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const navigate = useNavigate();
  if (!localStorage.getItem("adminName")) {
    navigate("/admin-login");
  }

  useEffect(() => {
    const fetchVistors = async () => {
      try {
        const response = await axios.get("/vistors");
        setVistors(response?.data);
        setFilteredVistors(response?.data); // Initialize filteredVistors with all vistors
        console.log(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchVistors();
  }, []);

  const handleDelete = async (e, vistorId) => {
    e.preventDefault();
    try {
      await axios.delete(`/vistors/${vistorId}`);
      setVistors(vistors.filter((vistor) => vistor._id !== vistorId));
      toast.success("تم الحذف بنجاح");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log(error.request);
        toast.error("حدث خطأ اثناء العملية. يرجى المحاولة مرة اخرى.");
      } else {
        console.log("Error", error.message);
        toast.error("حدث خطأ اثناء العملية. يرجى المحاولة مرة اخرى.");
      }
    }
  };

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleCheck = async (vistorId) => {
    try {
      const response = await axios.patch(`/vistors/${vistorId}`, {
        check: true,
      });
      toast.success("تم تحديث الحالة بنجاح");
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("حدث خطأ أثناء تحديث الحالة، يرجى المحاولة مرة أخرى.");
    }
  };

  useEffect(() => {
    setFilteredVistors(
      vistors.filter((vistor) => {
        return (
          (selectedGrade === "" || vistor.gradeAr === selectedGrade) &&
          (selectedType === "" || vistor.typeAr === selectedType)
        );
      })
    );
  }, [selectedGrade, selectedType, vistors]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "yyyy/M/d - h:mm:ss a", { locale: ar });
  };

  return (
    <div className="show-all-exams">
      <div className="container">
        <div className="table_component" role="region" tabIndex="0">
          <div className="table-head">
            <h1>طلبات الحجز</h1>
            <form style={{ width: "470px" }}>
              <span>فلترة</span>
              <select value={selectedGrade} onChange={handleGradeChange}>
                <option value="" hidden>
                  اختر الصف
                </option>
                <option value="الأول الإعدادي">الأول الإعدادي</option>
                <option value="الثاني الإعدادي">الثاني الإعدادي</option>
                <option value="الثالث الإعدادي">الثالث الإعدادي</option>
                <option value="الأول الثانوي">الأول الثانوي</option>
                <option value="الثاني الثانوي">الثاني الثانوي</option>
                <option value="الثالث الثانوي">الثالث الثانوي</option>
              </select>
              <select value={selectedType} onChange={handleTypeChange}>
                <option value="" hidden>
                  اختر النوع
                </option>
                <option value="سنتر">سنتر</option>
                <option value="أونلاين">أونلاين</option>
              </select>
              <button type="button">
                <i className="uil uil-sliders-v-alt"></i>
              </button>
            </form>
          </div>
          <table>
            <thead>
              <tr>
                <th>اسم الطالب</th>
                <th>رقم الهاتف</th>
                <th>رقم ولي الأمر</th>
                <th>رقم التحويل</th>
                <th>الصف</th>
                <th>المجموعة</th>
                <th>النوع</th>
                <th>صورة التحويل</th>
              </tr>
            </thead>
            <tbody>
              {filteredVistors.map((vistor, index) => (
                <tr key={index}>
                  <td>{vistor?.username}</td>
                  <td>{vistor?.phone}</td>
                  <td>{vistor?.parentPhone}</td>
                  <td>{vistor?.payPhone}</td>
                  <td>{vistor?.gradeAr}</td>
                  <td>{vistor?.time || "لا يوجد"}</td>
                  <td>{vistor?.typeAr}</td>
                  <td className="action-icons">
                    <div>
                      <i
                        className="uil uil-check show-exam"
                        style={{
                          backgroundColor: vistor.check ? "#25c600" : "#ccc",
                        }}
                        onClick={() => handleCheck(vistor._id)}
                      ></i>
                      <Link to={vistor.image} target="_blank">
                        <i className="uil uil-eye show-exam"></i>
                      </Link>
                      <form>
                        <button onClick={(e) => handleDelete(e, vistor._id)}>
                          <i className="uil uil-trash-alt delete-exam"></i>
                        </button>
                      </form>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowAllChecks;
