import React, { useContext, useEffect, useRef, useState } from "react";
import "./chat.css";
import { clientUrl, serverUrl, socketUrl } from "../../config";
import Avatar from "./Avatar";
import { UserContext } from "../UserContext";
import ChatHeader from "./ChatHeader";
import axios from "axios";
import Contact from "./Person";

const Chat = () => {
  const [ws, setWs] = useState(null);
  const [onlinePeople, setOnlinePeople] = useState({});
  const [offlinePeople, setOfflinePeople] = useState({});
  const [selectedVistorId, setSelectedVistorId] = useState(null);
  const [newMessageText, setNewMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({});
  const { username, id } = useContext(UserContext);
  const messagesBoxRef = useRef(null);

  useEffect(() => {
    const storedUnreadMessages = localStorage.getItem("unreadMessages");
    if (storedUnreadMessages) {
      setUnreadMessages(JSON.parse(storedUnreadMessages));
    }
    connectToWs();
  }, []);

  const connectToWs = () => {
    const ws = new WebSocket(`ws://${socketUrl}`);
    setWs(ws);
    ws.addEventListener("message", handleMessage);
    ws.addEventListener("close", () => {
      setTimeout(() => {
        console.log("Disconnected..!");
        connectToWs();
      }, 1000);
    });
  };

  useEffect(() => {
    const div = messagesBoxRef.current;
    if (div) {
      div.scrollTo({
        top: div.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  const handleMessage = (ev) => {
    try {
      const messageData = JSON.parse(ev.data);
      if ("online" in messageData) {
        showOnlinePeople(messageData.online);
      } else if ("text" in messageData) {
        setMessages((prev) => [
          ...prev,
          {
            ...messageData,
          },
        ]);

        if (messageData.sender !== selectedVistorId) {
          setUnreadMessages((prev) => {
            const updatedUnreadMessages = {
              ...prev,
              [messageData.sender]: (prev[messageData.sender] || 0) + 1,
            };
            localStorage.setItem(
              "unreadMessages",
              JSON.stringify(updatedUnreadMessages)
            );
            return updatedUnreadMessages;
          });
        }
      }
    } catch (error) {
      console.error("Failed to parse message", error);
    }
  };

  const handleSelectVisitor = (vistorId) => {
    setSelectedVistorId(vistorId);
    setUnreadMessages((prev) => {
      const updatedUnreadMessages = {
        ...prev,
        [vistorId]: 0,
      };
      localStorage.setItem(
        "unreadMessages",
        JSON.stringify(updatedUnreadMessages)
      );
      return updatedUnreadMessages;
    });
  };

  const showOnlinePeople = (peopleArray) => {
    const people = {};
    peopleArray.forEach(({ vistorId, username }) => {
      if (vistorId !== id) {
        people[vistorId] = username;
      }
    });
    setOnlinePeople(people);
  };

  const sendMessage = (e, file = null) => {
    if (e) {
      e.preventDefault();
    }

    ws.send(
      JSON.stringify({
        recipient: selectedVistorId,
        text: newMessageText,
        file,
      })
    );

    if (file) {
      axios
        .get(`/messages/${selectedVistorId}`)
        .then((response) => {
          setMessages(response?.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setNewMessageText("");
      setMessages((prev) => [
        ...prev,
        {
          text: newMessageText,
          sender: id,
          recipient: selectedVistorId,
          id: Date.now(),
        },
      ]);
    }
  };

  const sendFile = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      sendMessage(null, {
        name: e.target.files[0].name,
        data: reader.result,
      });
    };
  };

  const onlinePeopleExOurVistor = { ...onlinePeople };
  delete onlinePeopleExOurVistor[id];

  useEffect(() => {
    axios
      .get("/people")
      .then((response) => {
        const offlinePeopleArr = response.data
          .filter((p) => p._id !== id)
          .filter((p) => !Object.keys(onlinePeople).includes(p._id));
        const offlinePeople = {};
        offlinePeopleArr.forEach((p) => {
          offlinePeople[p._id] = p;
        });
        setOfflinePeople(offlinePeople);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [onlinePeople]);

  useEffect(() => {
    if (selectedVistorId) {
      axios
        .get(`/messages/${selectedVistorId}`)
        .then((response) => {
          setMessages(response?.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedVistorId]);

  useEffect(() => {
    messages.map((message, index) => console.log(message.sender));
  }, [messages]);

  const adminUser = Object.keys(onlinePeopleExOurVistor).find(
    (id) => onlinePeopleExOurVistor[id] === "admin100"
  );

  const filteredOnlinePeople =
    username === "admin100"
      ? onlinePeopleExOurVistor
      : adminUser
      ? { [adminUser]: onlinePeopleExOurVistor[adminUser] }
      : {};

  const filteredOfflinePeople =
    username === "admin100"
      ? offlinePeople
      : Object.keys(offlinePeople).find(
          (id) => offlinePeople[id].username === "admin100"
        )
      ? {
          [Object.keys(offlinePeople).find(
            (id) => offlinePeople[id].username === "admin100"
          )]:
            offlinePeople[
              Object.keys(offlinePeople).find(
                (id) => offlinePeople[id].username === "admin100"
              )
            ],
        }
      : {};

  return (
    <div className="chat">
      <div className="container">
        <div className="left">
          <ChatHeader />
          {Object.keys(filteredOnlinePeople).map((vistorId) => (
            <React.Fragment key={vistorId}>
              <Contact
                vistorId={vistorId}
                online={true}
                username={filteredOnlinePeople[vistorId]}
                onClick={() => handleSelectVisitor(vistorId)}
                selected={vistorId === selectedVistorId}
                unreadCount={unreadMessages[vistorId]}
              />
              <hr />
            </React.Fragment>
          ))}
          {Object.keys(filteredOfflinePeople).map((vistorId) => (
            <React.Fragment key={vistorId}>
              <Contact
                vistorId={vistorId}
                online={false}
                username={filteredOfflinePeople[vistorId]?.username}
                onClick={() => handleSelectVisitor(vistorId)}
                selected={vistorId === selectedVistorId}
                unreadCount={unreadMessages[vistorId]}
              />
              <hr />
            </React.Fragment>
          ))}
        </div>
        <div className="center">
          <div>
            {!selectedVistorId && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h2>قم باختيار محادثة للتحدث..!</h2>
              </div>
            )}
          </div>
          <div className="messages" ref={messagesBoxRef}>
            {!!selectedVistorId &&
              messages.map((message, index) => (
                <div
                  key={index}
                  className={`${
                    message.sender === id ? "my-message-up" : "come-message-up"
                  }`}
                >
                  <div
                    className={`message ${
                      message.sender === id ? "my-message" : "come-message"
                    }`}
                  >
                    {message.text}
                    {message.file && (
                      <div>
                        <a
                          href={`${message.file}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "var(--black-color)",
                            textDecoration: "underline",
                          }}
                        >
                          صورة <i class="uil uil-image"></i>
                        </a>
                        {/* <img
                          src={`${serverUrl}/uploads/${message.file}`}
                          alt=""
                        /> */}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {!!selectedVistorId && (
            <form className="chat-form" onSubmit={sendMessage}>
              <input
                type="text"
                placeholder="اكتب رسالتك هنا...."
                value={newMessageText}
                onChange={(e) => setNewMessageText(e.target.value)}
              />
              <label className="file-btn">
                <input type="file" accept="image/*" onChange={sendFile} />
                <i className="uil uil-paperclip"></i>
              </label>
              <button type="submit">
                <i className="uil uil-message"></i>
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
