import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
  const [username, setUsername] = useState(
    localStorage.getItem("username") || null
  );
  const [id, setId] = useState(localStorage.getItem("vistorId") || null);
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios
        .get("/vistors")
        .then((response) => {
          setId(response.data.id);
          setUsername(response.data.username);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("vistorId", response.data.vistorId);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [token]);

  const saveToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
  };

  return (
    <UserContext.Provider
      value={{
        username,
        setUsername,
        id,
        setId,
        token,
        setToken: saveToken,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
