import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./result.css";

const Result = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const response = await axios.get(`/results/${id}`);
        setResult(response.data);
      } catch (error) {
        console.error("Error fetching result:", error);
      }
    };

    fetchResult();
  }, [id]);

  if (!result) return <p>Loading...</p>;

  const imageUrl =
    result.score >= result.numberOfQuestions / 2
      ? "https://i.postimg.cc/FzgYpFvx/emoji-1.png"
      : "https://i.postimg.cc/sfwyYZdN/emoji-2.png";

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div className="result-container">
        <div className="result-content">
          <img src={imageUrl} alt="Emoji" className="emoji-img" />
          <h2>النتيجة</h2>
          <p className="score">{`${result.score}/${result.numberOfQuestions}`}</p>
          <div className="answers-info">
            <p className="correct-answers">
              الإجابات الصحيحة: {result.correctAnswers}
              <span role="img" aria-label="correct">
                ✅
              </span>
            </p>
            <p className="incorrect-answers">
              الإجابات الخاطئة: {result.incorrectAnswers}
              <span role="img" aria-label="incorrect">
                ❌
              </span>
            </p>
          </div>

          <Link className="select-another-quiz" to={"/online-exams"}>
            الذهاب الي اختبار آخر
          </Link>
        </div>
      </div>
      <div className="incorrect-questions">
        <div className="container">
          {result.incorrectQuestions.map((question, index) => (
            <div key={index} className="exam-details">
              <p className="exam-question">{question.questionText}</p>
              <div className="exam-answers">
                <ul>
                  {question.choices.map((choice, choiceIndex) => (
                    <li
                      key={choiceIndex}
                      className={
                        choiceIndex === question.selectedAnswer
                          ? "selected wrong"
                          : choiceIndex === question.correctAnswer
                          ? "correct"
                          : ""
                      }
                    >
                      {choiceIndex + 1}. {choice}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Result;
