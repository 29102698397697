import React, { useEffect, useState } from "react";
import "./review.css";
import axios from "axios";
import { Button } from "./Reviews";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ar } from "date-fns/locale";

const SchoolReviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get("/reviews");
        setReviews(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReviews();
  }, []);

  const handleDelete = async (e, reviewId) => {
    e.preventDefault();
    try {
      await axios.delete(`/reviews/${reviewId}`);
      setReviews(reviews.filter((review) => review._id !== reviewId));
      toast.success("تم الحذف بنجاح");
    } catch (error) {
      handleError(error);
    }
  };

  const handleApprove = async (e, reviewId) => {
    e.preventDefault();
    try {
      await axios.patch(`/reviews/${reviewId}`, {
        approve: true,
      });
      const updatedReviews = reviews.map((review) =>
        review._id === reviewId ? { ...review, approve: true } : review
      );
      setReviews(updatedReviews);
      toast.success("تمت الموافقة بنجاح");
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      toast.error(error.response.data.message);
    } else if (error.request) {
      console.log(error.request);
      toast.error("حدث خطأ أثناء العملية. يرجى المحاولة مرة أخرى.");
    } else {
      console.log("Error", error.message);
      toast.error("حدث خطأ أثناء العملية. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <div className="all-reviews ">
      <div className="container">
        {reviews.length === 0 && (
          <div className="no-exam-message">
            <i className="uil uil-exclamation-triangle"></i>
            <h1>لا يوجد تقييمات حتى الآن</h1>
            <Button text="جميع البيانات" path={`/all-data`} />
          </div>
        )}
        <div className="review-boxes">
          {reviews
            ?.sort((a, b) => {
              if (!a.approve && b.approve) return -1;
              if (a.approve && !b.approve) return 1;

              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((review) => (
              <div className="review-box" key={review._id}>
                <i
                  className="uil uil-trash-alt delete-review"
                  onClick={(e) => handleDelete(e, review._id)}
                ></i>
                {!review.approve && (
                  <i
                    className="uil uil-check delete-review"
                    onClick={(e) => handleApprove(e, review._id)}
                    style={{
                      right: "15px",
                      left: "unset",
                      backgroundColor: "#25c600",
                    }}
                  ></i>
                )}
                <img src="/assets/avatar-1.png" alt="طالب" />
                <div className="reviewer-data">
                  <span>{review.name}</span>
                  <span>
                    {review
                      ? format(new Date(review?.createdAt), "yyyy/M/d", {
                          locale: ar,
                        })
                      : ""}
                  </span>
                </div>
                <hr />
                <p className="review-message">{review.message}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SchoolReviews;
