import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./parents.css";
import { format } from "date-fns";
import { ar } from "date-fns/locale";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Parent = () => {
  const location = useLocation();
  const parentData = location.state;
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [lowDegreeCount, setLowDegreeCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [attendanceCount, setAttendanceCount] = useState(0);
  const [quizResults, setQuizResults] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [quizChartData, setQuizChartData] = useState([]);

  console.log(parentData);

  useEffect(() => {
    if (parentData?.lessons) {
      const lowDegrees = parentData.lessons.reduce((acc, lesson) => {
        const score = parseFloat(lesson.score);
        const finalScore = parseFloat(lesson.finalScore);
        if (
          !isNaN(score) &&
          !isNaN(finalScore) &&
          score <= 6 &&
          finalScore !== 0
        ) {
          return acc + 1;
        }
        return acc;
      }, 0);
      setLowDegreeCount(lowDegrees);

      const absents = parentData.lessons.reduce((acc, lesson) => {
        const score = lesson.score;
        if (score === "غائب") {
          return acc + 1;
        }
        return acc;
      }, 0);
      setAbsentCount(absents);

      const attendance = parentData.lessons.reduce((acc, lesson) => {
        const score = lesson.score;
        if (
          score !== "غائب" &&
          score !== "لم يتم تحديد درجة" &&
          lesson.finalScore !== "لم يتم تحديد درجة"
        ) {
          return acc + 1;
        }
        return acc;
      }, 0);
      setAttendanceCount(attendance);

      const chartData = parentData.lessons?.map((lesson, index) => ({
        name: `حصة ${index + 1}`,
        درجة:
          lesson.score !== "لم يتم تحديد درجة" ? parseFloat(lesson.score) : 0,
      }));
      setChartData(chartData);
    }

    const fetchQuizResults = async () => {
      try {
        const response = await axios.get(
          `/results/student/${parentData?.code}`
        );
        setQuizResults(response.data);
        const quizChartData = response?.data?.map((result, index) => ({
          name: `اختبار ${index + 1}`,
          درجة: result.score,
          "عدد الاسئلة": result.numberOfQuestions,
        }));
        setQuizChartData(quizChartData);
      } catch (error) {
        console.error("Error fetching quiz results:", error);
      }
    };

    fetchQuizResults();
  }, [parentData]);

  const Lesson = ({ number, score, finalScore }) => {
    const isLowDegree =
      score !== "لم يتم تحديد درجة" &&
      finalScore !== "لم يتم تحديد درجة" &&
      parseFloat(score) <= 6;

    let displayFinalScore;
    if (score === "غائب") {
      displayFinalScore = "غائب";
    } else if (
      finalScore === "لم يتم تحديد درجة" ||
      score === "لم يتم تحديد درجة"
    ) {
      displayFinalScore = "لم يتم تحديد درجة";
    } else if (
      finalScore !== "لم يتم تحديد درجة" &&
      score !== "لم يتم تحديد درجة"
    ) {
      displayFinalScore = `${score}/${finalScore}`;
    }

    return (
      <div className="student-degree">
        <span
          className={`lesson-name ${isLowDegree ? "low-degree" : ""}`}
          style={{
            color: isLowDegree ? "var(--red-color)" : "var(--black-color)",
          }}
        >
          حصة {number}
        </span>
        <span
          className="lesson-degree"
          style={{
            color: isLowDegree ? "var(--red-color)" : "var(--black-color)",
          }}
        >
          {displayFinalScore}
        </span>
      </div>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? "لم يتم تحديد تاريخ"
      : format(date, "yyyy/M/d - h:mm:ss a", { locale: ar });
  };

  const toggleRow = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  return (
    <div
      className="show-all-exams"
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <div
        className="container"
        style={{
          padding: "unset",
          borderRadius: "unset",
          backgroundColor: "unset",
          boxShadow: "unset",
        }}
      >
        <div
          className="table_component"
          role="region"
          tabIndex="0"
          style={{
            padding: "20px",
            borderRadius: "14px",
            backgroundColor: "var(--white-color)",
            boxShadow: "unset",
          }}
        >
          <div className="table-head">
            <h1>الدرجات</h1>
            <div className="filter-search">
              <form>
                <span>فلترة</span>
                <select>
                  <option value="" hidden>
                    اختر الشهر
                  </option>
                  <option value="أغسطس">أغسطس</option>
                  <option value="سبتمبر">سبتمبر</option>
                  <option value="أكتوبر">أكتوبر</option>
                  <option value="نوفمبر">نوفمبر</option>
                  <option value="ديسمبر">ديسمبر</option>
                  <option value="يناير">يناير</option>
                  <option value="فبراير">فبراير</option>
                  <option value="مارس">مارس</option>
                  <option value="أبريل">أبريل</option>
                  <option value="مايو">مايو</option>
                </select>
                <button>
                  <i className="uil uil-sliders-v-alt"></i>
                </button>
              </form>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>الرقم</th>
                <th>كود الطالب</th>
                <th>اسم الطالب</th>
                <th>المجموعة</th>
                <th>حالة الدفع</th>
                <th>اخر تحديث</th>
                <th>عرض الدرجات</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{parentData?.degreeId}</td>
                <td>{parentData?.code}</td>
                <td>{parentData?.name}</td>
                <td>{parentData?.time}</td>
                <td>{parentData?.payment}</td>
                <td>
                  {parentData?.updatedAt
                    ? formatDate(parentData.updatedAt)
                    : ""}
                </td>
                <td className="action-icons">
                  <div>
                    <i
                      className="uil uil-eye show-degrees"
                      onClick={() => toggleRow(parentData?.degreeId)}
                    ></i>
                  </div>
                </td>
              </tr>
              <tr
                className={`student-tr ${
                  expandedRowIndex === parentData?.degreeId ? "expanded" : ""
                }`}
              >
                <td colSpan={7} className="degrees-td">
                  <div className="student-degrees">
                    {parentData?.lessons?.map((lesson, index) => (
                      <Lesson
                        key={index}
                        number={index + 1}
                        score={lesson.score}
                        finalScore={lesson.finalScore}
                      />
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="table_component"
          role="region"
          tabIndex="0"
          style={{
            padding: "20px",
            borderRadius: "14px",
            backgroundColor: "var(--white-color)",
            boxShadow: "unset",
            marginTop: "20px",
          }}
        >
          <div className="table-head">
            <h1>درجات الاختبارات الإلكترونية</h1>
          </div>
          <table>
            <thead>
              <tr>
                <th>اسم الاختبار</th>
                <th>الدرجة</th>
                <th>الإجابات الصحيحة</th>
                <th>الإجابات الخاطئة</th>
                <th>عدد الأسئلة</th>
                <th>الحالة</th>
              </tr>
            </thead>
            <tbody>
              {quizResults?.map((result, index) => {
                const totalQuestions = result.numberOfQuestions;
                const halfQuestions = totalQuestions / 2;
                const status = result.score >= halfQuestions ? "ناجح" : "راسب";
                return (
                  <tr key={index}>
                    <td>{result.quizId.title}</td>
                    <td>
                      {result.score} / {totalQuestions}
                    </td>
                    <td>{result.correctAnswers}</td>
                    <td>{result.incorrectAnswers}</td>
                    <td>{totalQuestions}</td>
                    <td>{status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="parent-boxes">
          <div className="parent-box">
            <h2>عدد حصص المتابعة</h2>
            <span>{lowDegreeCount} حصص</span>
          </div>
          <div className="parent-box">
            <h2>عدد حصص الحضور</h2>
            <span>{attendanceCount} حصة</span>
          </div>
          <div className="parent-box">
            <h2>عدد حصص الغياب</h2>
            <span>{absentCount} غياب</span>
          </div>
        </div>

        <div
          className="charts"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <div
            className="table_component"
            role="region"
            tabIndex="0"
            style={{
              padding: "20px",
              borderRadius: "14px",
              backgroundColor: "var(--white-color)",
              boxShadow: "unset",
            }}
          >
            <div className="table-head">
              <h1>رسم بياني للدرجات</h1>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="درجة" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div
            className="table_component"
            role="region"
            tabIndex="0"
            style={{
              padding: "20px",
              borderRadius: "14px",
              backgroundColor: "var(--white-color)",
              boxShadow: "unset",
            }}
          >
            <div className="table-head">
              <h1>رسم بياني لدرجات الاختبارات الإلكترونية</h1>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={quizChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="درجة" stroke="#0075ff" />
                <Line type="monotone" dataKey="عدد الاسئلة" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parent;
