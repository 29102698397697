import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "../home/home";
import { RegisterAndLogin } from "../register/register";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../../redux/slices/usersApiSlice";
import { setCredentials } from "../../redux/slices/authSlice";

export default function Login() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [students, setStudents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get("/students");
        setStudents(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudents();
  }, []);

  const loginSubmitHandler = async (e) => {
    e.preventDefault();

    if (!code) {
      return toast.error("برجاء كتابة الكود..!");
    }
    if (!email) {
      return toast.error("برجاء كتابة الايميل..!");
    }
    if (!password) {
      return toast.error("برجاء كتابة كلمة المرور..!");
    }

    const isCodeValid = students.some(
      (student) => Number(student.code) === parseInt(code)
    );

    if (!isCodeValid) {
      return toast.error("الكود غير موجود..!");
    }

    const sonData = {
      code,
      email,
      password,
    };

    try {
      const response = await axios.post("/parents", sonData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("تم التسجيل..!");
      navigate("/parent", { state: response?.data });
    } catch (error) {
      toast.error("حدث خطأ ما برجاء إعادة المحاولة..!");
    }
  };

  return (
    <RegisterAndLogin className="login-section" header="تسجيل دخول">
      <div className="container">
        <div className="login-info">
          <div className="photo">
            <img
              src="/assets/avatar-1.png"
              alt="طالب"
            />
          </div>
          <form onSubmit={loginSubmitHandler}>
            <div className="form-group">
              <label>كود الطالب</label>
              <input
                type="number"
                placeholder="الكود"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>ايميل الطالب</label>
              <input
                type="email"
                placeholder="الايميل"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>كلمة مرور الطالب</label>
              <input
                type="password"
                placeholder="كلمة المرور"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="general-btn create-btn">
              تسجيل{" "}
            </button>
          </form>
        </div>
      </div>
    </RegisterAndLogin>
  );
}
