import { Link, useNavigate } from "react-router-dom";
import "../dashboard.css";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Sidebar from "../Sidebar";
import { Button } from "../../home/home";

const AllDegress = () => {
  const navigate = useNavigate();
  if (!localStorage.getItem("adminName")) {
    navigate("/admin-login");
  }

  return (
    <div className="add-exam">
      <div className="container">
        <Sidebar />
        <div className="add-content">
          {/* <h1 className="data-h1">جميع البيانات</h1> */}
          <div className="all-data-container">
            <div className="all-data-item">
              <div className="item-text">
                <i className="uil uil-clipboard-alt"></i>
                <h2>الصف الأول الإعدادي</h2>
              </div>
              <Link to={"/dashboard/add-1prep-degrees"}>تصفح</Link>
            </div>
            <hr />
            <div className="all-data-item">
              <div className="item-text">
                <i className="uil uil-clipboard-alt"></i>
                <h2>الصف الثاني الإعدادي</h2>
              </div>
              <Link to={"/dashboard/add-2prep-degrees"}>تصفح</Link>
            </div>
            <hr />
            <div className="all-data-item">
              <div className="item-text">
                <i className="uil uil-clipboard"></i>
                <h2>الصف الثالث الإعدادي</h2>
              </div>
              <Link to={"/dashboard/add-3prep-degrees"}>تصفح</Link>
            </div>
            <hr />
            <div className="all-data-item">
              <div className="item-text">
                <i className="uil uil-video"></i>
                <h2>الصف الأول الثانوي</h2>
              </div>
              <Link to={"/dashboard/add-1sec-degrees"}>تصفح</Link>
            </div>
            <hr />
            <div className="all-data-item">
              <div className="item-text">
                <i className="uil uil-record-audio"></i>
                <h2>الصف الثاني الثانوي</h2>
              </div>
              <Link to={"/dashboard/add-2sec-degrees"}>تصفح</Link>
            </div>
            <hr />
            <div className="all-data-item">
              <div className="item-text">
                <i className="uil uil-books"></i>
                <h2>الصف الثالث الثانوي</h2>
              </div>
              <Link to={"/dashboard/add-3sec-degrees"}>تصفح</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllDegress;
